import React from "react";
import { LinearProgress, useTheme } from "@mui/material";
import Box from "@mui/material/Box";

const DividerWithLoading = ({ loading, marginTop }) => {
  const theme = useTheme();
  return (
    <Box marginTop={marginTop}>
      {!!loading ? (
        <LinearProgress color="secondary" style={{ height: "5px" }} />
      ) : (
        <Box bgcolor={theme.palette.secondary.main} height={5} />
      )}
    </Box>
  );
};

export default DividerWithLoading;
