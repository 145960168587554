import React from "react";
import Modal from "../shared/Modal";
import { useTranslation } from "react-i18next";
import { Formik, Form } from "formik";
import { Box, Button } from "@mui/material";
import useValidationSchema from "./validation";
import { useMutation } from "react-query";
import { changePassword } from "../../api";
import { useSnackbar } from "notistack";
import FormikTextField from "../shared/FormikTextField";

const ChangePasswordModal = ({ onClose, open }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const validationSchema = useValidationSchema();

  const { mutate: handleChangePassword, isLoading } = useMutation(
    (passwords) => changePassword(passwords),
    {
      onSuccess: () => {
        onClose();
        enqueueSnackbar(t("PASSWORD_UPDATED"), { variant: "success" });
      },
      onError: () => {
        //TODO to be updated when backend shows actual error - so far just generic "bad request" info
        enqueueSnackbar(t("ERROR_DEFAULT"), { variant: "error" });
      },
    }
  );

  return (
    <Modal open={open} onClose={onClose} title={t("CHANGE_PASSWORD")}>
      <Box pb={2} px={2} minWidth={400}>
        <Formik
          initialValues={{
            oldPassword: "",
            newPassword: "",
            newPasswordRepeat: "",
          }}
          onSubmit={(values) => {
            handleChangePassword({
              newPassword: values.newPassword,
              oldPassword: values.oldPassword,
            });
          }}
          validationSchema={validationSchema}
        >
          <Form>
            <FormikTextField
              name={"oldPassword"}
              label={`${t("OLD_PASSWORD")} *`}
              type={"password"}
            />
            <FormikTextField
              name={"newPassword"}
              label={`${t("NEW_PASSWORD")} *`}
              type={"password"}
            />
            <FormikTextField
              name={"newPasswordRepeat"}
              label={`${t("NEW_PASSWORD_REPEAT")} *`}
              type={"password"}
              boxProps={{ mb: 1 }}
            />
            <Button
              color="secondary"
              variant="contained"
              fullWidth
              type="submit"
              disabled={isLoading}
            >
              {t("CHANGE_PASSWORD")}
            </Button>
          </Form>
        </Formik>
      </Box>
    </Modal>
  );
};

export default ChangePasswordModal;
