import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import Paper from "@mui/material/Paper";
import { useLocation } from "react-router-dom";
import { useTheme } from "@mui/material";
import Users from "./users";
import Products from "./products";
import Configuration from "./configuration";
import { useEffect } from "react";
import useTokensState from "../../hooks/useTokensState";
import Dashboard from "./dasboard";
import Tickets from "./tickets";
import Customers from "./customers";
import CustomerDetails from "./customerDetails";
import useCustomersStore from "../../hooks/useCustomerStore";
import Reports from "./reports";
import Transactions from "./transactions";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export default function VerticalTabs(props) {
  const location = useLocation();
  const value = Number(new URLSearchParams(location.search).get("tab"));
  const { t } = useTranslation();
  const theme = useTheme();
  const { token, cleanTokens } = useTokensState();
  const handleChange = (event, newValue) => {
    props.history.push("/admin-panel?tab=" + newValue);
  };
  const { pageType: customersPageType } = useCustomersStore();
  useEffect(() => {
    if (!token) {
      cleanTokens();
      window.location = "/login";
    }
  }, [token]);

  return (
    <Box sx={{ flexGrow: 1, bgcolor: "background.paper", display: "flex" }}>
      <Tabs
        style={{ minWidth: 150 }}
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        sx={{ borderRight: 3, borderColor: theme.palette.secondary.light }}
      >
        <Tab label={t("DASHBOARD")} {...a11yProps(0)} />
        <Tab label={t("ADMINS")} {...a11yProps(1)} />
        <Tab label={t("PRODUCTS")} {...a11yProps(2)} />
        <Tab label={t("CONFIGURATION")} {...a11yProps(3)} />
        <Tab label={t("USERS")} {...a11yProps(4)} />
        <Tab label={t("TICKETS")} {...a11yProps(5)} />
        <Tab label={t("REPORTS")} {...a11yProps(6)} />
        <Tab label={t("TRANSACTIONS")} {...a11yProps(7)} />
      </Tabs>
      <Paper sx={{ width: "100%", overflow: "hidden" }} variant="none">
        <TabPanel value={value} index={0} style={tabPanelStyle}>
          <Dashboard />
        </TabPanel>
        <TabPanel value={value} index={1} style={tabPanelStyle}>
          <Users />
        </TabPanel>
        <TabPanel value={value} index={2} style={tabPanelStyle}>
          <Products />
        </TabPanel>
        <TabPanel value={value} index={3} style={tabPanelStyle}>
          <Configuration />
        </TabPanel>
        <TabPanel value={value} index={4} style={tabPanelStyle}>
          {customersPageType === "LIST" ? <Customers /> : <CustomerDetails />}
        </TabPanel>
        <TabPanel value={value} index={5} style={tabPanelStyle}>
          <Tickets />
        </TabPanel>
        <TabPanel value={value} index={6} style={tabPanelStyle}>
          <Reports />
        </TabPanel>
        <TabPanel value={value} index={7} style={tabPanelStyle}>
          <Transactions />
        </TabPanel>
      </Paper>
    </Box>
  );
}

const tabPanelStyle = { width: "100%" };
