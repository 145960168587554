import {useTranslation} from "react-i18next";
import React, {useState} from "react";
import {sortType, TWO_MINUTES} from "../../../constants";
import {useQuery} from "react-query";
import queryKeys from "../../../api/queryKeys";
import {fetchConfiguration, fetchCustomer} from "../../../api";
import TableTitle from "../../shared/TableTitle";
import {Box} from "@mui/material";
import SearchTableComponent from "../../shared/SearchTableComponent";
import handleEnterPress from "../../../utils/handleEnterPress";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import PaginationComponent from "../../shared/PaginationComponent";
import ConfigurationTable from "../configuration/ConfigurationTable";
import handleTableSort from "../../../utils/handleTableSort";
import CustomerTable from "./CustomerTable";

const Customers = () => {
    const { t } = useTranslation();
    const [searchText, setSearchText] = useState("");
    const [query, setQuery] = useState({
        page: 0,
        size: 10,
        searchText: "",
        sortBy: "id",
        sortType: sortType.asc,
    });

    const { data: customer, isLoading } = useQuery(
        [queryKeys.CUSTOMER, query],
        () => fetchCustomer(query),
        { staleTime: TWO_MINUTES }
    );

    const handleSearch = () => setQuery({ ...query, page: 0, searchText });
    return (
        <>
            <TableTitle title={t("USER")} />
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                }}
            >
                <SearchTableComponent
                    handleSearch={handleSearch}
                    handleChange={(e) => setSearchText(e.target.value)}
                    handleKeyDown={(e) => handleEnterPress(e, handleSearch)}
                />
            </Box>
            <CustomerTable
                customer={customer?.data.content}
                loading={isLoading}
                query={query}
                handleSort={(sortBy) => handleTableSort(sortBy, query, setQuery)}
            />
            {customer?.data && (
                <PaginationComponent
                    totalPages={customer.data.totalPages}
                    number={customer.data.number}
                    size={customer.data.size}
                    setQuery={setQuery}
                    query={query}
                />
            )}
        </>
    );
};
export default Customers;
