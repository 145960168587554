import React, { useState } from "react";
import Modal from "../shared/Modal";
import { useTranslation } from "react-i18next";
import { Box, Button, DialogContentText } from "@mui/material";
import { Form, Formik } from "formik";
import FormikTextField from "../shared/FormikTextField";
import { useSnackbar } from "notistack";
import { useResetPasswordValidationSchema } from "./validation";
import { resetEmailPassword } from "../../api";

const ResetPasswordModal = ({ open, onClose }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const validationSchema = useResetPasswordValidationSchema();

  const handleResetPassword = async ({ email }) => {
    setIsLoading(true);
    try {
      const res = await resetEmailPassword(email);
      if (res.status === 200) {
        enqueueSnackbar(t("SENT_NEW_PASSWORD"), { variant: "Success" });
        onClose();
      }
    } catch (error) {
      enqueueSnackbar(t("RESET_PASSOWRD_WRONG"), { variant: "Error" });
    }
    setIsLoading(false);
  };

  return (
    <Modal open={open} onClose={onClose} title={t("RESET_PASSWORD")}>
      <Box px={3} pb={3}>
        <DialogContentText>{t("RESET_PASSWORD_TEXT")}</DialogContentText>
        <Formik
          initialValues={{
            email: "",
          }}
          onSubmit={handleResetPassword}
          validationSchema={validationSchema}
        >
          <Form>
            <FormikTextField name={"email"} label={`${t("EMAIL")} *`} />
            <Button
              color="secondary"
              variant="contained"
              fullWidth
              type="submit"
              disabled={isLoading}
            >
              {t("SEND")}
            </Button>
          </Form>
        </Formik>
      </Box>
    </Modal>
  );
};

export default ResetPasswordModal;
