import React from "react";
import DialogTitle from "@mui/material/DialogTitle";
import { Dialog, IconButton, Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const Modal = ({ onClose, title, open, children, ...rest }) => {
  return (
    <Dialog onClose={onClose} open={open} {...rest}>
      <Box display={"flex"} justifyContent={"space-between"}>
        <DialogTitle>{title}</DialogTitle>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      {children}
    </Dialog>
  );
};

export default Modal;
