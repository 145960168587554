import { sortType } from "../constants";

const handleTableSort = (sortBy, query, setQuery) => {
  if (sortBy !== query.sortBy) {
    setQuery({ ...query, page: 0, sortBy, sortType: sortType.asc });
  } else if (query.sortType === sortType.asc) {
    setQuery({ ...query, page: 0, sortType: sortType.desc });
  } else {
    setQuery({ ...query, page: 0, sortType: sortType.asc });
  }
};

export default handleTableSort;
