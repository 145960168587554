import React from "react";

const mainTheme = {
  palette: {
    zIndex: { tooltip: 99999 },
    type: "light",
    error: {
      main: "#0d7aac",
    },
    primary: {
      main: "#212121",
      background: "#69d0c4",
    },
    tram: "#333333",
    bus: "#0d7aac",
    ropeWay: "#d0464d",
    secondary: {
      main: "#0d7aac",
      light: "rgb(240,240,240)",
      background: "#f1f1f1",
      accent: "#0d7aac",
    },
    text: {
      primary: "#212121",
      secondary: "#9e9e9e",
      light: "#bdbdbd",
    },
    outdated: {
      main: "#DDDDDD",
    },
  },
  sort: {
    activeStyle: {
      color: "#0d7aac",
      fontWeight: "bold",
    },
    inactiveStyle: {
      color: "inherit",
    },
  },
};

export default mainTheme;
