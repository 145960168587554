import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { SnackbarProvider } from "notistack";
import { Zoom } from "@mui/material";
import ResponsiveAppBar from "./components/app-bar/AppBar";
import mainTheme from "./theme/theme";
import Login from "./components/login/Login";
import AdminPanel from "./components/admin-panel/AdminPanel";
import Footer from "./components/footer/Footer";
import { QueryClient, QueryClientProvider } from "react-query";
import { api, apiToken, apiV3 } from "./api";
import environmentLocal from "./assets/environment.json";
import { AppProvider } from "./AppContext";
const queryClient = new QueryClient();

function App() {
  const [apiFromEnvironment, setApiFromEnvironment] = useState();
  const theme = createTheme(mainTheme);

  const getEnvironment = async () => {
    try {
      const environment = await fetch("/static/environment.json");
      const environmentJson = await environment.json();
      setApiFromEnvironment(await environmentJson?.backendURL);
      apiV3.defaults.baseURL = environmentLocal?.backendURLV3;
      api.defaults.baseURL = await environmentJson?.backendURL;
      apiToken.defaults.baseURL = await environmentJson?.backendURL;
    } catch (e) {
      setApiFromEnvironment(environmentLocal.backendURL);
      apiV3.defaults.baseURL = environmentLocal?.backendURLV3;
      api.defaults.baseURL = environmentLocal.backendURL;
      apiToken.defaults.baseURL = environmentLocal.backendURL;
    }
  };

  useEffect(() => {
    getEnvironment();
  }, []);

  if (apiFromEnvironment !== api.defaults.baseURL) return null;
  else
    return (
      <AppProvider>
        <QueryClientProvider client={queryClient}>
          <ThemeProvider theme={theme}>
            <BrowserRouter>
              <SnackbarProvider
                preventDuplicate
                TransitionComponent={Zoom}
                maxSnack={5}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                <div
                  className="Site"
                  style={{ minHeight: "100vh", alignItems: "stretch" }}
                >
                  <ResponsiveAppBar />
                  <div className="Site-content" style={{ paddingTop: "30px" }}>
                    <Switch>
                      <Route path="/" exact component={AdminPanel} />
                      <Route path="/login" component={Login} />
                      <Route path="/admin-panel" component={AdminPanel} />
                    </Switch>
                  </div>
                  <Footer />
                </div>
              </SnackbarProvider>
            </BrowserRouter>
          </ThemeProvider>
        </QueryClientProvider>
      </AppProvider>
    );
}

export default App;
