import React, { useState } from "react";
import ChartLine from "../../shared/Charts/ChartLine";
import ChartPie from "../../shared/Charts/ChartPie";
import { Box, FormControl, Grid, InputLabel, Select } from "@mui/material";
import TableTitle from "../../shared/TableTitle";
import { useTranslation } from "react-i18next";
import ChartBar from "../../shared/Charts/ChartBar";
import { fetchCharts, fetchChartsPeriods } from "../../../api";
import queryKeys from "../../../api/queryKeys";
import { useQuery } from "react-query";
import MenuItem from "@mui/material/MenuItem";
import DividerWithLoading from "../../shared/DividerWithLoading";
import ChartGrid from "../../shared/Charts/ChartGrid";
import Modal from "../../shared/Modal";
import UserForm from "../users/UserForm";
import PeriodCustomForm from "./PeriodCustomForm";
import Button from "@mui/material/Button";
import Utils from "../../../utils/utils";

const Dashboard = () => {
  const [periodModal, setPeriodModal] = useState(false);
  const { t, i18n } = useTranslation();
  const x = Array(30);
  const [query, setQuery] = useState({
    period: "",
    periodFrom: "",
    periodTo: "",
  });
  const { data: charts, isLoading } = useQuery(
    [queryKeys.CHARTS, query, i18n.language],
    ({ signal }) => fetchCharts({ ...query, signal }),
    { staleTime: 0 }
  );
  const { data: chartsPeriod } = useQuery(
    [queryKeys.CHARTS_PERIOD, i18n.language],
    () => fetchChartsPeriods(),
    { staleTime: 0 }
  );
  const handleSearch = (period) => {
    console.log(period);
    if (period === "CUSTOM") {
      setPeriodModal(true);
    } else {
      setQuery({ ...query, period: period, periodFrom: "", periodTo: "" });
    }
  };
  const handleCustomPeriod = (value) => {
    setQuery({
      ...query,
      period: "CUSTOM",
      periodFrom: value.periodFrom,
      periodTo: value.periodTo,
    });
    setPeriodModal(false);
  };

  const dataItems =
    chartsPeriod?.data?.data !== undefined
      ? Object.entries(chartsPeriod?.data?.data)
      : null;
  return (
    <Box>
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          paddingBottom: 0,
          marginBottom: 0,
        }}
      >
        <Grid container spacing={1}>
          <Grid item md={10}>
            <TableTitle title={t("DASHBOARD")} />
          </Grid>
          <Grid item md={2}>
            {dataItems &&
              Object.entries(dataItems).length > 0 &&
              charts !== undefined &&
              charts.data !== undefined && (
                <>
                  <FormControl fullWidth>
                    <InputLabel id="period-select-dashboard">
                      {t("PERIOD")}{" "}
                    </InputLabel>
                    <Select
                      fullWidth
                      id={"period-select-dashboard"}
                      label={t("PERIOD")}
                      value={charts.data.period}
                      onChange={(v) => handleSearch(v.target.value)}
                    >
                      {dataItems.map((value) => (
                        <MenuItem value={value[0]} key={value[0]}>
                          {value[1]}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </>
              )}
          </Grid>
          {query.periodFrom !== "" && (
            <>
              <Grid item md={9}></Grid>
              <Grid item md={3}>
                <Grid
                  container
                  spacing={1}
                  justifyItems={"center"}
                  alignItems={"center"}
                >
                  <Grid item md={8}>
                    <h4>{t("PERIOD_CUSTOM")}:</h4>
                    <p>
                      {Utils.getDate(query.periodFrom)} -{" "}
                      {Utils.getDate(query.periodTo)}
                    </p>
                  </Grid>
                  <Grid
                    item
                    md={2}
                    justifyItems={"center"}
                    alignItems={"center"}
                  >
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => setPeriodModal(true)}
                    >
                      <Box pt={0.5}>{t("EDIT")}</Box>
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}

          <Grid item md={12}>
            <DividerWithLoading loading={isLoading} marginTop={0} />
          </Grid>
        </Grid>
      </Box>
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          flexWrap: "wrap",
        }}
      >
        {/*<Summary />*/}
        {charts?.data?.data.map((chart) =>
          chart.chartValue.series[0].data.length > 0 ? (
            chart.type === "GRID" ? (
              <ChartGrid {...chart} />
            ) : chart.type === "PIE" ? (
              <ChartPie {...chart} />
            ) : chart.type === "BAR" ? (
              <ChartBar {...chart} />
            ) : chart.type === "LINE" ? (
              <ChartLine {...chart} />
            ) : null
          ) : null
        )}
      </Box>
      <Modal
        onClose={() => setPeriodModal(false)}
        open={periodModal}
        title={t("PERIOD_CUSTOM")}
      >
        <PeriodCustomForm
          buttonText={t("SEARCH")}
          initialValues={query}
          handleSubmit={(values) => handleCustomPeriod(values)}
        />
      </Modal>
    </Box>
  );
};

export default Dashboard;
