import React from "react";
import { Divider, IconButton, useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const BackToPanelHeader = ({ headerTitle, handleArrowBackClick }) => {
  const theme = useTheme();
  return (
    <>
      <Typography variant="h4" style={{ marginBottom: 10 }}>
        <IconButton onClick={handleArrowBackClick}>
          <ArrowBackIcon />
        </IconButton>
        {headerTitle}
      </Typography>
      <Divider
        color={theme.palette.secondary.main}
        className={"Colour-divider"}
      />
    </>
  );
};

export default BackToPanelHeader;
