import { useTranslation } from "react-i18next";
import Modal from "../../shared/Modal";
import * as React from "react";
import { Form, Formik } from "formik";
import { Box, Button, Grid } from "@mui/material";
import FormikTextField from "../../shared/FormikTextField";
import { useSnackbar } from "notistack";
import { useMutation, useQueryClient } from "react-query";
import { editRefundTicket } from "../../../api";
import queryKeys from "../../../api/queryKeys";
import useValidationSchema from "./validation";
import FormikSelectField from "../../shared/FormikSelectField";
import { JUSTFICATIONS, REFUND_CATEGORIES } from "../../../constants";
import MenuItem from "@mui/material/MenuItem";

const EditRefundModal = ({ onClose, ticketNumber, editedRefund }) => {
  const { enqueueSnackbar } = useSnackbar();
  const validationSchema = useValidationSchema();
  const queryClient = useQueryClient();

  const { mutate: handleRefund, isLoading } = useMutation(
    (values) =>
      editRefundTicket({
        ...values,
        refundId: editedRefund?.refundId,
        ticketNumber,
      }),
    {
      onSuccess: () => {
        onClose();
        enqueueSnackbar(t("EDITED"), { variant: "success" });
        queryClient.invalidateQueries({
          queryKey: [queryKeys.TICKETS_REFUNDS],
        });
        queryClient.invalidateQueries({
          queryKey: [queryKeys.TICKETS],
        });
      },
      onError: (err) => {
        enqueueSnackbar(err?.response?.data?.message || t("ERROR_DEFAULT"), {
          variant: "error",
        });
      },
    }
  );
  const { t } = useTranslation();

  return (
    <Modal
      onClose={onClose}
      open={Boolean(editedRefund)}
      title={t("REFUND_DETAILS")}
      fullWidth
      maxWidth={"sm"}
    >
      <Box pb={2} px={4}>
        <Formik
          initialValues={{
            amount: editedRefund?.amount,
            comment: editedRefund?.comment,
            category: editedRefund?.category,
            justification: editedRefund?.justification,
          }}
          onSubmit={handleRefund}
          enableReinitialize
          validationSchema={validationSchema}
        >
          {({ handleSubmit }) => (
            <Form>
              <Grid container spacing={1}>
                <Grid item md={12}>
                  <FormikTextField
                    name={"amount"}
                    label={`${t("amount")}`}
                    type="number"
                    disabled
                  />
                </Grid>
                <Grid item md={12}>
                  <FormikSelectField
                    options={[]}
                    name={"category"}
                    label={t("refunds.category")}
                  >
                    {REFUND_CATEGORIES.map((value) => (
                      <MenuItem value={value} key={value}>
                        {t("refundCategories." + value)}
                      </MenuItem>
                    ))}
                  </FormikSelectField>
                </Grid>
                <Grid item md={12}>
                  <FormikSelectField
                    options={[]}
                    name={"justification"}
                    label={t("refunds.justification")}
                  >
                    {JUSTFICATIONS.map((value) => (
                      <MenuItem value={value} key={value}>
                        {t("justifications." + value)}
                      </MenuItem>
                    ))}
                  </FormikSelectField>
                </Grid>
                <Grid item md={12}>
                  <FormikTextField
                    name={"comment"}
                    label={`${t("comment")}`}
                    maxRows={4}
                    multiline
                  />
                </Grid>
              </Grid>

              <Box py={2}>
                <Button
                  disabled={isLoading}
                  color="secondary"
                  variant="contained"
                  fullWidth
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  {t("EDIT")}
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};
export default EditRefundModal;
