import React from "react";
import Modal from "./Modal";
import { Box, Button } from "@mui/material";
import { useTranslation } from "react-i18next";

const DeleteConfirmationModal = ({
  open,
  onClose,
  handleDelete,
  isLoading,
  title,
  extraInfo,
}) => {
  const { t } = useTranslation();

  return (
    <Modal open={open} onClose={onClose} title={title}>
      <Box px={2}>
        {extraInfo}
        <Box
          pb={2}
          minWidth={{ md: 500 }}
          display={"flex"}
          justifyContent={"space-between"}
          flexWrap={"wrap"}
        >
          <Button
            color="secondary"
            variant="outlined"
            sx={{ width: { md: "45%", xs: "100%" } }}
            onClick={onClose}
          >
            {t("NO")}
          </Button>
          <Button
            color="secondary"
            variant="contained"
            sx={{ width: { md: "45%", xs: "100%" } }}
            onClick={handleDelete}
            disabled={isLoading}
          >
            {t("YES")}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default DeleteConfirmationModal;
