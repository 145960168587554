import React from "react";
import { Form, Formik } from "formik";
import FormikTextField from "../../shared/FormikTextField";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Tooltip,
} from "@mui/material";
import useValidationSchema from "./validation";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import queryKeys from "../../../api/queryKeys";
import { fetchUserRoles } from "../../../api";
import { TEN_MINUTES } from "../../../constants";

const UserForm = ({
  handleSubmit,
  isLoading,
  buttonText,
  initialValues,
  edit,
  handleResetPassword,
  editLoading,
}) => {
  const { t } = useTranslation();
  const validationSchema = useValidationSchema();
  const { data: allowedRoles, isLoading: rolesLoading } = useQuery(
    [queryKeys.ALLOWED_ROLES],
    () => fetchUserRoles(),
    { staleTime: 0 }
  );
  return (
    <Box pb={2} px={2} minWidth={400}>
      {!rolesLoading && (
        <Formik
          initialValues={{
            id: undefined,
            firstName: "",
            lastName: "",
            email: "",
            role: allowedRoles.data?.[0],
            ...initialValues,
          }}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {({ values, errors, setFieldValue, touched }) => (
            <Form>
              <FormikTextField
                name={"firstName"}
                label={`${t("FIRST_NAME")} *`}
              />
              <FormikTextField
                name={"lastName"}
                label={`${t("LAST_NAME")} *`}
              />
              <FormikTextField
                name={"email"}
                label={`${t("EMAIL")} *`}
                disabled={edit}
              />
              {edit && (
                <Tooltip title={t("RESET_PASSWORD_TOOLTIP")}>
                  <Button
                    color="secondary"
                    fullWidth
                    variant={"outlined"}
                    type={"button"}
                    onClick={handleResetPassword}
                    sx={{ mb: 1 }}
                    disabled={editLoading}
                  >
                    {t("RESET_PASSWORD")}
                  </Button>
                </Tooltip>
              )}
              <FormLabel component="legend">{`${t("ROLES")} *`}</FormLabel>
              <Box display={"flex"} flexDirection={"column"}>
                {allowedRoles?.data?.map((role, index) => (
                  <FormControlLabel
                    key={role + "_" + index}
                    control={<Checkbox checked={values.role === role} />}
                    name="roles"
                    value={values.role}
                    label={t(role)}
                    onChange={() => setFieldValue("role", role)}
                  />
                ))}
              </Box>
              <FormHelperText error>
                {touched.role && errors.role}
              </FormHelperText>
              <Box py={0.5} />
              <Button
                color="secondary"
                variant="contained"
                fullWidth
                type="submit"
                disabled={isLoading}
              >
                {buttonText}
              </Button>
            </Form>
          )}
        </Formik>
      )}
    </Box>
  );
};

export default UserForm;
