import React from "react";
import { Form, Formik } from "formik";
import FormikTextField from "../../shared/FormikTextField";
import { Box, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import FormikCheckboxField from "../../shared/FormikCheckboxField";
import useValidationSchema from "./validation";
import useTokensState from "../../../hooks/useTokensState";

const ConfigurationForm = ({
  handleSubmit,
  isLoading,
  buttonText,
  initialValues,
}) => {
  const { t } = useTranslation();
  const validationSchema = useValidationSchema();
    const {getRole} = useTokensState();
  return (
    <Box pb={2} px={2} minWidth={400}>
      <Formik
        initialValues={{
          id: undefined,
          name: "",
          value: "",
          description: "",
          required: false,
          ...initialValues,
        }}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        <Form>
          <FormikTextField name={"name"} label={`${t("NAME")} *`} />
          <FormikTextField name={"value"} label={`${t("VALUE")} *`} />
          <FormikTextField
            name={"description"}
            label={`${t("DESCRIPTION")} *`}
          />
          <FormikCheckboxField
            name={"required"}
            label={t("REQUIRED")}
            wrapperProps={{ mt: -1 }}
          />
          <Box py={0.5} />
          <Button
            color="secondary"
            variant="contained"
            fullWidth
            type="submit"
            disabled={isLoading || getRole() === 'READ_ONLY'}
          >
            {buttonText}
          </Button>
        </Form>
      </Formik>
    </Box>
  );
};

export default ConfigurationForm;
