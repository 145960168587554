import React, { Component } from "react";
import dayjs from "dayjs";

class Utils extends Component {
  static dateToString_DD_MM_YYY(d) {
    let year = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d);
    let month = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(d);
    let day = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(d);
    return `${day}-${month}-${year}`;
  }

  static getBase64FromUrl(url, callback) {
    var xhr = new XMLHttpRequest();
    xhr.onload = function () {
      var reader = new FileReader();
      reader.onloadend = function () {
        callback(reader.result);
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.open("GET", url);
    xhr.responseType = "blob";
    xhr.send();
  }
  static getFormatDate(date) {
    return dayjs(date).utc(true).format("DD.MM.YYYY HH:mm:ss");
  }
  static getDate(date){
    return dayjs(date).format("DD.MM.YYYY")
  }

  render() {
    return <div></div>;
  }
  static parseJwt (token) {
    if (token){
      let base64Url = token.split('.')[1];
      let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));

      return JSON.parse(jsonPayload);
    }

  }
}

export default Utils;
