import React, {useState} from "react";
import {useQuery} from "react-query";
import {fetchUsers} from "../../../api";
import {sortType, TWO_MINUTES} from "../../../constants";
import UsersTable from "./UsersTable";
import {useTranslation} from "react-i18next";
import PaginationComponent from "../../shared/PaginationComponent";
import handleTableSort from "../../../utils/handleTableSort";
import SearchTableComponent from "../../shared/SearchTableComponent";
import handleEnterPress from "../../../utils/handleEnterPress";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import {Box} from "@mui/material";
import AddUserModal from "./AddUserModal";
import queryKeys from "../../../api/queryKeys";
import useUserStore from "../../../hooks/useUserStore";
import TableTitle from "../../shared/TableTitle";
import useTokensState from "../../../hooks/useTokensState";

const Users = () => {
    const {t} = useTranslation();
    const [addUserModalVisible, setAddUserModalVisible] = useState(false);
    const {isAdmin} = useUserStore();
    const [searchText, setSearchText] = useState("");
    const [query, setQuery] = useState({
        page: 0,
        size: 5,
        searchText: "",
        sortBy: "id",
        sortType: sortType.asc,
    });

    const {data: users, isLoading} = useQuery(
        [queryKeys.USERS, query],
        () => fetchUsers(query),
        {staleTime: TWO_MINUTES}
    );
    const handleSearch = () => setQuery({...query, page: 0, searchText});
    const {getRole} = useTokensState();
    return (
        <>
            <TableTitle title={t("ADMINS")}/>
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                }}
            >
                <SearchTableComponent
                    handleSearch={handleSearch}
                    handleChange={(e) => setSearchText(e.target.value)}
                    handleKeyDown={(e) => handleEnterPress(e, handleSearch)}
                />
                {getRole() === 'SUPER_ADMIN' || getRole() === 'ADMIN' &&(
                    <Button
                        variant="contained"
                        endIcon={<AddIcon/>}
                        onClick={() => setAddUserModalVisible(true)}
                    >
                        <Box pt={0.5}>{t("ADD")}</Box>
                    </Button>
                )}
            </Box>
            <UsersTable
                users={users?.data.content}
                loading={isLoading}
                query={query}
                handleSort={(sortBy) => handleTableSort(sortBy, query, setQuery)}
            />
            {users?.data && (
                <PaginationComponent
                    totalPages={users.data.totalPages}
                    number={users.data.number}
                    size={users.data.size}
                    setQuery={setQuery}
                    query={query}
                />
            )}
            <AddUserModal
                onClose={() => setAddUserModalVisible(false)}
                open={!!addUserModalVisible}
            />
        </>
    );
};

export default Users;
