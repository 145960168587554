import * as React from "react";
import { useTranslation } from "react-i18next";
import Modal from "../../shared/Modal";
import { useMutation, useQueryClient } from "react-query";
import { addConfiguration } from "../../../api";
import { useSnackbar } from "notistack";
import ConfigurationForm from "./ConfigurationForm";
import queryKeys from "../../../api/queryKeys";

function AddConfigurationModal({ onClose, open }) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { mutate: handleAddConfiguration, isLoading } = useMutation(
    (newConfiguration) => addConfiguration(newConfiguration),
    {
      onSuccess: () => {
        onClose();
        enqueueSnackbar(t("CREATED_SUCCESSFULLY"), { variant: "success" });
        queryClient.invalidateQueries({ queryKey: [queryKeys.CONFIGURATION] });
      },
      onError: () => {
        enqueueSnackbar(t("ERROR_DEFAULT"), { variant: "error" });
      },
    }
  );

  return (
    <Modal onClose={onClose} open={open} title={t("CONFIGURATION")}>
      <ConfigurationForm
        handleSubmit={handleAddConfiguration}
        isLoading={isLoading}
        buttonText={t("SAVE")}
      />
    </Modal>
  );
}

export default AddConfigurationModal;
