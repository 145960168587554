import { useTranslation } from "react-i18next";
import { string, object, number } from "yup";

const useValidationSchema = () => {
  const { t } = useTranslation();
  return object({
    currency: string().required(t("FIELD_REQUIRED")),
    amount: number()
      .min(0.01, t("FIELD_REQUIRED"))
      .required(t("FIELD_REQUIRED")),
  });
};

export default useValidationSchema;
