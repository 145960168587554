import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCellWithSort from "../../shared/TableCellWithSort";
import StandardTableCell from "../../shared/StandardTableCell";
import TableBody from "@mui/material/TableBody";
import EditProductModal from "./EditProductModal";
import StyledTable from "../../shared/StyledTable";

const ProductTable = ({ loading, products, query, handleSort }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [productBeingEdited, setProductBeingEdited] = useState(undefined);

  const { sortType, sortBy } = query;

  return (
    <>
      <StyledTable loading={loading}>
        <TableHead
          sx={{
            backgroundColor: theme.palette.secondary.background,
            color: theme.palette.text.primary,
          }}
        >
          <TableRow>
            {["id", "name", "sku", "price"].map((key) => (
              <TableCellWithSort
                key={key}
                sortOrder={sortType}
                handleSortClick={() => handleSort(key)}
                displayedText={t("product." + key)}
                selected={sortBy === key}
              />
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {products?.map((row) => (
            <TableRow
              key={row.id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              onClick={() => setProductBeingEdited(row)}
            >
              <StandardTableCell content={row.id} />
              <StandardTableCell content={row.name} />
              <StandardTableCell content={row.sku} />
              <StandardTableCell content={row.price + " " + row.currency} />
            </TableRow>
          ))}
        </TableBody>
      </StyledTable>
      <EditProductModal
        open={!!productBeingEdited}
        onClose={() => setProductBeingEdited(undefined)}
        productBeingEdited={productBeingEdited}
      />
    </>
  );
};
export default ProductTable;
