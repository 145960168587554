import { useTranslation } from "react-i18next";
import { string, object } from "yup";

const useValidationSchema = () => {
  const { t } = useTranslation();
  return object({
    name: string().required(t("FIELD_REQUIRED")),
    value: string().required(t("FIELD_REQUIRED")),
    description: string().required(t("FIELD_REQUIRED")),
  });
};

export default useValidationSchema;
