import { useTranslation } from "react-i18next";
import Modal from "../../shared/Modal";
import * as React from "react";
import { Form, Formik } from "formik";
import { Box, Button, Grid } from "@mui/material";
import FormikTextField from "../../shared/FormikTextField";
import { useSnackbar } from "notistack";
import { useMutation, useQueryClient } from "react-query";
import { refundTransaction } from "../../../api";
import queryKeys from "../../../api/queryKeys";
import useValidationSchema from "./validation";

const RefundModal = ({ onClose, transactionId }) => {
  const { enqueueSnackbar } = useSnackbar();
  const validationSchema = useValidationSchema();
  const queryClient = useQueryClient();

  const { mutate: handleRefund, isLoading } = useMutation(
    (values) => refundTransaction({ ...values, transactionId }),
    {
      onSuccess: () => {
        onClose();
        enqueueSnackbar(t("REFUNDED"), { variant: "success" });
        queryClient.invalidateQueries({ queryKey: [queryKeys.TRANSACTIONS] });
      },
      onError: (err) => {
        enqueueSnackbar(err?.response?.data?.message || t("ERROR_DEFAULT"), {
          variant: "error",
        });
      },
    }
  );

  const { t } = useTranslation();
  return (
    <Modal
      onClose={onClose}
      open={Boolean(transactionId)}
      title={t("REFUND_DETAILS")}
      fullWidth
      maxWidth={"sm"}
    >
      <Box pb={2} px={4}>
        <Formik
          initialValues={{
            amount: 0,
            currency: "EUR",
          }}
          onSubmit={handleRefund}
          enableReinitialize
          validationSchema={validationSchema}
        >
          <Form>
            <Grid container spacing={1}>
              <Grid item md={12}>
                <FormikTextField
                  name={"amount"}
                  label={`${t("transactions.amount")}`}
                  type="number"
                />
              </Grid>
              <Grid item md={12}>
                <FormikTextField
                  name={"currency"}
                  label={`${t("transactions.currency")}`}
                />
              </Grid>
            </Grid>
            <Box py={2}>
              <Button
                disabled={isLoading}
                color="secondary"
                variant="contained"
                fullWidth
                type="submit"
              >
                {t("REFUND")}
              </Button>
            </Box>
          </Form>
        </Formik>
      </Box>
    </Modal>
  );
};
export default RefundModal;
