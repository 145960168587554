import React from "react";
import Typography from "@mui/material/Typography";

const TableTitle = ({ title }) => {
  return (
    <Typography variant="h4" component={"span"} sx={{ mb: 10 }}>
      {title}
    </Typography>
  );
};

export default TableTitle;
