import React from "react";
import { TextField, Box, FormHelperText } from "@mui/material";
import { useField, Field } from "formik";

const MuiTextField = ({ field, form, ...props }) => {
  return <TextField {...field} {...props} multiline={Boolean(props.maxRows)} />;
};

const FormikTextField = ({ name, label, boxProps, ...props }) => {
  const [_, meta] = useField(name);
  return (
    <Box py={1} {...boxProps}>
      <Field
        fullWidth
        component={MuiTextField}
        id={name}
        name={name}
        label={label}
        {...props}
      />
      {meta.touched && meta.error && (
        <FormHelperText error>{meta.error}</FormHelperText>
      )}
    </Box>
  );
};

export default FormikTextField;
