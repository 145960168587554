import {useTranslation} from "react-i18next";
import useCustomersStore from "../../../hooks/useCustomerStore";
import BackToPanelHeader from "../../shared/BackToPanelHeader";
import {Box, Card, Grid} from "@mui/material";
import AccordionComponent from "../../shared/AccordionComponent";
import Tickets from "../tickets";
import Typography from "@mui/material/Typography";

const CustomerDetails = () => {
    const { t, i18n } = useTranslation();
    const { customer, showCustomersList } = useCustomersStore();

    return (
        <div>
            <BackToPanelHeader
                headerTitle={t("CUSTOMER_DETAILS")}
                handleArrowBackClick={() => showCustomersList()}
            />
            <Grid container>
                <Grid item xs={12}>
                    <Card
                        variant="outlined"
                        style={{ padding: 20, margin: 10, minHeight: 30 }}
                    >
                        <Typography variant="h6">
                            <Box sx={{ fontWeight: "bold" }}>
                                {t("USER_ID")}{customer.id}
                            </Box>
                        </Typography>
                    </Card>
                </Grid>
            </Grid>
            <Card variant="outlined" style={{ padding: 20, margin: 10 }}>
                <AccordionComponent text={t("TICKETS")}>
                    <Tickets customerId={customer.id} isShowingInTicket={false} />
                </AccordionComponent>
            </Card>
        </div>
    )
};
export default CustomerDetails;
