import { useContext } from "react";
import { AppContext } from "../AppContext";
import jwt_decode from "jwt-decode";
import Utils from "../utils/utils";
const useTokensState = () => {
  const { tokenState, refreshTokenState } = useContext(AppContext);
  const [token, setToken] = tokenState;
  const [refreshToken, setRefreshToken] = refreshTokenState;

  const isTokenExpired = () => {
    const decoded = token ? jwt_decode(token) : undefined;
    return token ? Date.now() >= decoded.exp * 1000 - 5000 : false;
  };

  const cleanTokens = () => {
    setToken("");
    setRefreshToken("");
  };
  const getRole = () => {
    if (token){
      let result = Utils.parseJwt(token)
      return result.role;
    }
  }
  return {
    token,
    setToken,
    refreshToken,
    setRefreshToken,
    cleanTokens,
    getRole,
    tokenExpired: isTokenExpired(),
  };
};
export default useTokensState;
