import React from "react";
import TableCell from "@mui/material/TableCell";
import { useTheme } from "@mui/material/styles";

const StandardTableCell = ({ content, error }) => {
  const theme = useTheme();
  return (
    <TableCell
      align="center"
      style={error ? { color: theme.palette.error.main } : {}}
    >
      {content}
    </TableCell>
  );
};

export default StandardTableCell;
