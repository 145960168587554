import * as React from "react";
import { useTranslation } from "react-i18next";
import Modal from "../../shared/Modal";
import { useMutation, useQueryClient } from "react-query";
import { deleteConfiguration, editConfiguration } from "../../../api";
import { useSnackbar } from "notistack";
import ConfigurationForm from "./ConfigurationForm";
import queryKeys from "../../../api/queryKeys";
import { Box, Button } from "@mui/material";
import DeleteConfirmationModal from "../../shared/DeleteConfirmationModal";
import { useState } from "react";
import useTokensState from "../../../hooks/useTokensState";

function EditConfigurationModal({ onClose, open, configurationBeingEdited }) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const [deleteConfirmationModal, setDeleteConfirmationModal] = useState(false);

  const { mutate: handleEdit, isLoading: editIsLoading } = useMutation(
    (newConfiguration) => editConfiguration(newConfiguration),
    {
      onSuccess: () => {
        onClose();
        enqueueSnackbar(t("UPDATE_SUCCESSFULLY"), { variant: "success" });
        queryClient.invalidateQueries({ queryKey: [queryKeys.CONFIGURATION] });
      },
      onError: () => {
        enqueueSnackbar(t("ERROR_DEFAULT"), { variant: "error" });
      },
    }
  );

  const { mutate: handleDelete, isLoading: deleteIsLoading } = useMutation(
    () => deleteConfiguration(configurationBeingEdited.id),
    {
      onSuccess: () => {
        onClose();
        enqueueSnackbar(t("DELETED_SUCCESSFULLY"), { variant: "success" });
        queryClient.invalidateQueries({ queryKey: [queryKeys.CONFIGURATION] });
      },
      onError: () => {
        enqueueSnackbar(t("ERROR_DEFAULT"), { variant: "error" });
      },
    }
  );

  const handleDeleteClick = () => {
    if (configurationBeingEdited.required) {
      enqueueSnackbar(t("ERROR_CONFIGURATION_REQUIRE"), {
        variant: "Error",
      });
      setDeleteConfirmationModal(false);
    } else {
      handleDelete();
    }
  };
    const {getRole} = useTokensState();
  return (
    <Modal onClose={onClose} open={open} title={t("CONFIGURATION")}>
      <ConfigurationForm
        handleSubmit={handleEdit}
        isLoading={editIsLoading}
        buttonText={t("SAVE")}
        initialValues={configurationBeingEdited}
      />
      <Box px={2} pb={2}>
        <Button
          variant="outlined"
          fullWidth
          onClick={() => setDeleteConfirmationModal(true)}
          disabled={getRole() === 'READ_ONLY'}
        >
          {t("DELETE_CONFIGURATION")}
        </Button>
      </Box>
      <DeleteConfirmationModal
        open={deleteConfirmationModal}
        onClose={() => setDeleteConfirmationModal(false)}
        isLoading={deleteIsLoading}
        handleDelete={handleDeleteClick}
        title={t("DELETE_CONFIGURATION_CONFIRMATION")}
      />
    </Modal>
  );
}

export default EditConfigurationModal;
