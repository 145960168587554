import { useTranslation } from "react-i18next";
import { object, string } from "yup";

const useLoginValidationSchema = () => {
  const { t } = useTranslation();
  return object({
    login: string().email(t("EMAIL_WARNING")).required(t("FIELD_REQUIRED")),
    password: string().required(t("FIELD_REQUIRED")),
  });
};

const useResetPasswordValidationSchema = () => {
  const { t } = useTranslation();
  return object({
    email: string().email(t("EMAIL_WARNING")).required(t("FIELD_REQUIRED")),
  });
};

export { useLoginValidationSchema, useResetPasswordValidationSchema };
