import * as React from "react";
import { LineChart } from "@mui/x-charts";
import theme from "../../../../theme/theme";
import { Box, Typography } from "@mui/material";
import useGetChartWidth from "../../../../hooks/useGetChartWidth";

const ChartLine = ({ title, chartValue, size }) => {
  const width = useGetChartWidth(size);
  const { xAxis, series } = chartValue;
  return (
    <Box
      style={{
        width: width + "px",
        backgroundColor: theme.palette.secondary.light,
        borderRadius: 20,
        marginBottom: 15,
      }}
    >
      <Box sx={{ padding: "20px 20px 0px" }}>
        <Typography>{title}</Typography>
      </Box>
      <LineChart
        xAxis={xAxis}
        series={series.map((s) => ({
          color: theme.palette.secondary.main,
          ...s,
        }))}
        width={width}
        height={300}
        sx={{
          ".MuiMarkElement-root": {
            display: "none",
          },
        }}
      />
    </Box>
  );
};

export default ChartLine;
