import React from "react";
import TableCell from "@mui/material/TableCell";
import { TableSortLabel, useTheme } from "@mui/material";
import { sortType } from "../../constants";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import withWidth from "@mui/material/Hidden/withWidth";

const TableCellWithSort = ({
  selected,
  sortOrder,
  handleSortClick,
  displayedText,
    width,
}) => {
  const theme = useTheme();
  return (
    <TableCell align="center" width={width}>
      <TableSortLabel
        style={
          selected
            ? {
                color: theme.palette.secondary.main,
                fontWeight: "bold",
              }
            : { color: "inherit" }
        }
        active={selected}
        IconComponent={
          sortOrder === sortType.asc ? ArrowDownwardIcon : ArrowUpwardIcon
        }
        onClick={handleSortClick}
      >
        {displayedText}
      </TableSortLabel>
    </TableCell>
  );
};

export default TableCellWithSort;
