import DividerWithLoading from "./DividerWithLoading";
import Paper from "@mui/material/Paper";
import React from "react";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";

const StyledTable = ({ loading, children, headerHeight = 100 }) => {
  return (
    <>
      <DividerWithLoading loading={loading} marginTop={1} />
      <TableContainer
        component={Paper}
        sx={{ marginTop: 1 }}
        variant={"outlined"}
        style={{ maxHeight: window.innerHeight - 300 - headerHeight }}
      >
        <Table
          stickyHeader
          sx={{
            width: "100%",
            cursor: "pointer",
          }}
          aria-label="simple table"
        >
          {children}
        </Table>
      </TableContainer>
    </>
  );
};

export default StyledTable;
