import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCellWithSort from "../../shared/TableCellWithSort";
import TableBody from "@mui/material/TableBody";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import TableCell from "@mui/material/TableCell";
import useCustomersStore from "../../../hooks/useCustomerStore";
import { fetchCustomerById } from "../../../api";
import { useHistory } from "react-router-dom";
import { Button } from "@mui/material";
import EditTicketModal from "./EditTicketModal";
import formatDate from "../../../utils/formatDate";
import { DAY_MONTH_YEAR_HOURS_MINUTES_SECONDS } from "../../../constants";
import StyledTable from "../../shared/StyledTable";

const TicketTable = ({
  loading,
  ticket,
  query,
  handleSort,
  isShowingInTicket,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { showCustomerDetails } = useCustomersStore();
  const { pageType: customersPageType } = useCustomersStore();
  const { sortType, sortBy } = query;
  const history = useHistory();
  const [ticketBeingEdited, setTicketBeingEdited] = useState(undefined);

  function handleShowCustomerDetails(id) {
    if (id !== undefined && id !== null) {
      fetchCustomerById(id).then((customer) => {
        showCustomerDetails(customer.data);
        if (isShowingInTicket) {
          history.push("/admin-panel?tab=4");
        }
      });
    }
  }

  return (
    <>
      <StyledTable loading={loading}>
        <TableHead
          sx={{
            backgroundColor: theme.palette.secondary.background,
            color: theme.palette.text.primary,
          }}
        >
          <TableRow style={{ cursor: "default" }}>
            <TableCellWithSort
              style={{ cursor: "pointer" }}
              sortOrder={sortType}
              handleSortClick={() => handleSort("id")}
              displayedText={t("ID")}
              selected={sortBy === "id"}
            />
            <TableCellWithSort
              style={{ cursor: "pointer" }}
              sortOrder={sortType}
              handleSortClick={() => handleSort("created")}
              displayedText={t("CREATED")}
              selected={sortBy === "created"}
            />

            <TableCell align="center">{t("USER_ID")}</TableCell>
            <TableCell align="center">{t("PRODUCT")}</TableCell>

            <TableCellWithSort
              style={{ cursor: "pointer" }}
              sortOrder={sortType}
              handleSortClick={() => handleSort("ticketNumber")}
              displayedText={t("TICKET_NUMBER")}
              selected={sortBy === "ticketNumber"}
            />
            <TableCell align="center">{t("TRANSACTION_ID")}</TableCell>
            <TableCellWithSort
              style={{ cursor: "pointer" }}
              sortOrder={sortType}
              handleSortClick={() => handleSort("remainingRefundAmount")}
              displayedText={t("REFUND_AMOUNT")}
              selected={sortBy === "remainingRefundAmount"}
            />
            <TableCellWithSort
              style={{ cursor: "pointer" }}
              sortOrder={sortType}
              handleSortClick={() => handleSort("isCancelled")}
              displayedText={t("IS_CANCELLED")}
              selected={sortBy === "isCancelled"}
            />
            <TableCellWithSort
              style={{ cursor: "pointer" }}
              sortOrder={sortType}
              handleSortClick={() => handleSort("isValidated")}
              displayedText={t("IS_VALIDATED")}
              selected={sortBy === "isValidated"}
            />
            <TableCellWithSort
              style={{ cursor: "pointer" }}
              sortOrder={sortType}
              handleSortClick={() => handleSort("isRefunded")}
              displayedText={t("IS_REFUNDED")}
              selected={sortBy === "isRefunded"}
            />
            <TableCell align="center"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {ticket?.map((row) => (
            <TableRow
              key={row.id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              style={{ cursor: "default" }}
            >
              <TableCell align="center">{row.id}</TableCell>
              <TableCell align="center">
                {formatDate(row.created, DAY_MONTH_YEAR_HOURS_MINUTES_SECONDS)}
              </TableCell>
              <TableCell
                align="center"
                style={{ cursor: "pointer" }}
                onClick={() => handleShowCustomerDetails(row.receipt?.userId)}
              >
                {row.receipt?.userId}
              </TableCell>
              <TableCell align="center">
                {row.product?.name + " (" + row.product?.id + ")"}
              </TableCell>
              <TableCell align="center">{row.ticketNumber}</TableCell>
              <TableCell align="center">{row?.transaction?.id}</TableCell>
              <TableCell align="center">
                {t("AMOUNT_PATTERN", { val: row.remainingRefundAmount })}
              </TableCell>
              <TableCell align="center">
                {row.isCancelled ? (
                  <CheckBoxIcon />
                ) : (
                  <CheckBoxOutlineBlankIcon />
                )}
              </TableCell>
              <TableCell align="center">
                {row.isValidated ? (
                  <CheckBoxIcon />
                ) : (
                  <CheckBoxOutlineBlankIcon />
                )}
              </TableCell>
              <TableCell align="center">
                {row.isRefunded ? (
                  <CheckBoxIcon />
                ) : (
                  <CheckBoxOutlineBlankIcon />
                )}
              </TableCell>
              <TableCell>
                <Button
                  variant="outlined"
                  onClick={() => setTicketBeingEdited(row)}
                >
                  {t("DETAILS")}
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </StyledTable>
      <EditTicketModal
        open={!!ticketBeingEdited}
        onClose={() => setTicketBeingEdited(undefined)}
        ticketBeingEdited={ticketBeingEdited}
      />
    </>
  );
};
export default TicketTable;
