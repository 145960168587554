import { useTranslation } from "react-i18next";
import Modal from "../../shared/Modal";

import * as React from "react";
import TransactionForm from "./TransactionForm";

const TransactionDetails = ({ onClose, open, transactionDetails }) => {
  const handleSubmit = (values) => {};
  const { t } = useTranslation();
  return (
    <Modal
      onClose={onClose}
      open={open}
      title={t("TRANSACTION_DETAILS")}
      fullWidth
      maxWidth={"lg"}
    >
      <TransactionForm
        handleSubmit={handleSubmit}
        initialValues={transactionDetails}
      />
    </Modal>
  );
};
export default TransactionDetails;
