import { useTranslation } from "react-i18next";
import Modal from "../../shared/Modal";
import * as React from "react";
import TicketForm from "./TicketForm";

const EditTicketModal = ({ onClose, open, ticketBeingEdited }) => {
  const handleSubmit = (values) => {};
  const { t } = useTranslation();
  return (
    <Modal
      onClose={onClose}
      open={open}
      title={t("TICKET_DETAIL")}
      fullWidth
      maxWidth={"lg"}
    >
      <TicketForm
        handleSubmit={handleSubmit}
        initialValues={ticketBeingEdited}
      />
    </Modal>
  );
};
export default EditTicketModal;
