import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCellWithSort from "../../shared/TableCellWithSort";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import { Button } from "@mui/material";
import TransactionDetails from "./TransactionDetails";
import formatDate from "../../../utils/formatDate";
import { DAY_MONTH_YEAR_HOURS_MINUTES_SECONDS } from "../../../constants";
import RefundModal from "./RefundModal";
import StyledTable from "../../shared/StyledTable";

const TransactionTable = ({ loading, transactions, query, handleSort }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { sortType, sortBy } = query;
  const [transactionDetails, setTransactionDetails] = useState(undefined);
  const [refundTransactionId, setRefundTransactionId] = useState(undefined);

  return (
    <>
      <StyledTable loading={loading} headerHeight={40}>
        <TableHead
          sx={{
            backgroundColor: theme.palette.secondary.background,
            color: theme.palette.text.primary,
          }}
        >
          <TableRow style={{ cursor: "default" }}>
            <TableCellWithSort
              sortOrder={sortType}
              handleSortClick={() => handleSort("id")}
              displayedText={t("ID")}
              selected={sortBy === "id"}
            />
            <TableCellWithSort
              sortOrder={sortType}
              handleSortClick={() => handleSort("created")}
              displayedText={t("transactions.created")}
              selected={sortBy === "created"}
            />
            <TableCellWithSort
              sortOrder={sortType}
              handleSortClick={() => handleSort("amount")}
              displayedText={t("transactions.amount")}
              selected={sortBy === "amount"}
            />
            <TableCellWithSort
              sortOrder={sortType}
              handleSortClick={() => handleSort("amountUsed")}
              displayedText={t("transactions.amountUsed")}
              selected={sortBy === "amountUsed"}
            />
            <TableCellWithSort
              sortOrder={sortType}
              handleSortClick={() => handleSort("remainingRefundAmount")}
              displayedText={t("transactions.remainingRefundAmount")}
              selected={sortBy === "remainingRefundAmount"}
            />
            <TableCellWithSort
              sortOrder={sortType}
              handleSortClick={() => handleSort("currency")}
              displayedText={t("transactions.currency")}
              selected={sortBy === "currency"}
            />
            <TableCellWithSort
              sortOrder={sortType}
              handleSortClick={() => handleSort("type")}
              displayedText={t("transactions.type")}
              selected={sortBy === "type"}
            />
            <TableCellWithSort
              sortOrder={sortType}
              handleSortClick={() => handleSort("currentStatus")}
              displayedText={t("transactions.currentStatus")}
              selected={sortBy === "currentStatus"}
            />
            <TableCellWithSort
              sortOrder={sortType}
              handleSortClick={() => handleSort("cartId")}
              displayedText={t("transactions.cartId")}
              selected={sortBy === "cartId"}
            />
            <TableCell align="center"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {transactions?.map((row) => (
            <TableRow
              key={row.id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              style={{ cursor: "default" }}
            >
              <TableCell align="center">{row.transactionId}</TableCell>
              <TableCell align="center">
                {formatDate(row.created, DAY_MONTH_YEAR_HOURS_MINUTES_SECONDS)}
              </TableCell>
              <TableCell align="center">{row.amount}</TableCell>
              <TableCell align="center">{row.amountUsed}</TableCell>
              <TableCell align="center">{row.remainingRefundAmount}</TableCell>
              <TableCell align="center">{row.currency}</TableCell>
              <TableCell align="center">{row.type}</TableCell>
              <TableCell align="center">{row.currentStatus}</TableCell>
              <TableCell align="center">{row.cartId}</TableCell>
              <TableCell>
                <Button
                  sx={{ width: "100%" }}
                  variant="outlined"
                  onClick={(e) => setTransactionDetails(row)}
                >
                  {t("DETAILS")}
                </Button>
                {!row.isRefunded && (
                  <Button
                    sx={{ mt: 1, width: "100%" }}
                    variant="outlined"
                    onClick={() => setRefundTransactionId(row.transactionId)}
                  >
                    {t("REFUND")}
                  </Button>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </StyledTable>
      <TransactionDetails
        open={!!transactionDetails}
        onClose={() => setTransactionDetails(undefined)}
        transactionDetails={transactionDetails}
      />
      <RefundModal
        transactionId={refundTransactionId}
        onClose={() => setRefundTransactionId(undefined)}
      />
    </>
  );
};
export default TransactionTable;
