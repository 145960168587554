import React from "react";
import Box from "@mui/material/Box";
import { withRouter } from "react-router-dom";
import LoginForm from "./LoginForm";
import ResetPasswordModal from "./ResetPasswordModal";

const Login = (props) => {
  const [resetPasswordModalVisible, setResetPasswordModalVisible] =
    React.useState(false);

  return (
    <>
      <Box display="flex" justifyContent="center" sx={{ mt: 6 }}>
        <LoginForm
          history={props.history}
          handlePasswordForgotClick={() => setResetPasswordModalVisible(true)}
        />
      </Box>
      <ResetPasswordModal
        open={resetPasswordModalVisible}
        onClose={() => setResetPasswordModalVisible(false)}
      />
    </>
  );
};

export default withRouter(Login);
