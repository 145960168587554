import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import DividerWithLoading from "../../shared/DividerWithLoading";
import React from "react";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import formatDate from "../../../utils/formatDate";
import { DAY_MONTH_YEAR_HOURS_MINUTES_SECONDS } from "../../../constants";
import { Button } from "@mui/material";
import EditRefundModal from "./EditRefundModal";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";

const RefundHistory = ({ loading, refunds, ticketNumber }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [editedRefund, setEditedRefund] = React.useState(undefined);
  return (
    <>
      <DividerWithLoading loading={loading} marginTop={1} />
      <TableContainer
        component={Paper}
        sx={{ marginTop: 1 }}
        variant={"outlined"}
      >
        <Table
          sx={{ width: "100%", cursor: "pointer" }}
          aria-label="simple table"
        >
          <TableHead
            sx={{
              backgroundColor: theme.palette.secondary.background,
              color: theme.palette.text.primary,
            }}
          >
            <TableRow style={{ cursor: "default" }}>
              <TableCell align="center">{t("CREATED")}</TableCell>
              <TableCell align="center">{t("UPDATED")}</TableCell>
              <TableCell align="center">{t("refunds.category")}</TableCell>
              <TableCell align="center">{t("refunds.justification")}</TableCell>
              <TableCell align="center">{t("refunds.note")}</TableCell>
              <TableCell align="center">{t("refunds.value")}</TableCell>
              <TableCell align="center">{t("refunds.createdByUser")}</TableCell>
              <TableCell align="center">{t("invalidateTicket")}</TableCell>
              <TableCell align="center">{t("refunds.edit")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {refunds?.map((row) => (
              <TableRow
                key={row.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                style={{ cursor: "default" }}
              >
                <TableCell align="center">
                  {formatDate(
                    row.created,
                    DAY_MONTH_YEAR_HOURS_MINUTES_SECONDS
                  )}
                </TableCell>
                <TableCell align="center">
                  {formatDate(
                    row.updated,
                    DAY_MONTH_YEAR_HOURS_MINUTES_SECONDS
                  )}
                </TableCell>
                <TableCell align="center">
                  {row.category ? t("refundCategories." + row.category) : ""}
                </TableCell>
                <TableCell align="center">
                  {row.justification
                    ? t("justifications." + row.justification)
                    : ""}
                </TableCell>
                <TableCell align="center">{row.comment}</TableCell>
                <TableCell align="center">
                  {row.amount ? t("AMOUNT_PATTERN", { val: row.amount }) : null}
                </TableCell>
                <TableCell align="center">{row.createdByUser}</TableCell>
                <TableCell align="center">
                  {row.invalidateTicket ? (
                    <CheckBoxIcon />
                  ) : (
                    <CheckBoxOutlineBlankIcon />
                  )}
                </TableCell>
                <TableCell align="center">
                  <Button
                    variant="outlined"
                    onClick={() => setEditedRefund(row)}
                  >
                    {t("refunds.edit")}
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <EditRefundModal
        ticketNumber={ticketNumber}
        editedRefund={editedRefund}
        onClose={() => setEditedRefund(undefined)}
      />
    </>
  );
};
export default RefundHistory;
