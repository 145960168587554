import * as React from "react";
import { PieChart } from "@mui/x-charts";
import theme from "../../../../theme/theme";
import { Box, Typography } from "@mui/material";
import useGetChartWidth from "../../../../hooks/useGetChartWidth";

const ChartPie = ({ title, chartValue, size }) => {
  const width = useGetChartWidth(size);
  chartValue.series[0] = {
    ...chartValue.series[0],
    innerRadius: 20,
    outerRadius: 100,
    paddingAngle: 5,
    cornerRadius: 5,
    startAngle: -180,
    endAngle: 180,
    cx: 60,
    cy: 150,
  };
  const { series } = chartValue;
  return (
    <Box
      style={{
        width: width + "px",
        backgroundColor: theme.palette.secondary.light,
        borderRadius: 20,
        marginBottom: 15,
      }}
    >
      <Box sx={{ padding: "20px 20px 0px" }}>
        <Typography>{title}</Typography>
      </Box>
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <PieChart series={series} width={width / 2.5} height={300} />
      </Box>
    </Box>
  );
};

export default ChartPie;
