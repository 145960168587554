import * as React from "react";
import { useTranslation } from "react-i18next";
import Modal from "../../shared/Modal";
import { useMutation, useQueryClient } from "react-query";
import { addUser } from "../../../api";
import { useSnackbar } from "notistack";
import UserForm from "./UserForm";
import queryKeys from "../../../api/queryKeys";

function AddUserModal({ onClose, open }) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const { mutate: handleAddUser, isLoading } = useMutation(
    (newUser) => addUser(newUser),
    {
      onSuccess: () => {
        onClose();
        enqueueSnackbar(t("CREATED_SUCCESSFULLY"), { variant: "success" });
        queryClient.invalidateQueries({ queryKey: [queryKeys.USERS] });
      },
      onError: () => {
        enqueueSnackbar(t("ERROR_DEFAULT"), { variant: "error" });
      },
    }
  );

  return (
    <Modal onClose={onClose} open={open} title={t("ADD_ADMIN")}>
      <UserForm
        handleSubmit={(values) => handleAddUser(values)}
        isLoading={isLoading}
        buttonText={t("ADD_USER")}
      />
    </Modal>
  );
}

export default AddUserModal;
