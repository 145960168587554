import * as React from "react";
import { BarChart } from "@mui/x-charts";
import theme from "../../../../theme/theme";
import { Box, Typography } from "@mui/material";
import useGetChartWidth from "../../../../hooks/useGetChartWidth";

const ChartBar = ({ title, chartValue, size }) => {
  const width = useGetChartWidth(size);

  const { xAxis, series } = chartValue;

  return (
    <Box
      style={{
        width: width + "px",
        backgroundColor: theme.palette.secondary.light,
        borderRadius: 20,
        marginBottom: 15,
      }}
    >
      <Box sx={{ padding: "20px 20px 0px" }}>
        <Typography>{title}</Typography>
      </Box>
      <BarChart
        width={width}
        height={300}
        series={series.map((s) => ({
          color: theme.palette.secondary.main,
          ...s,
        }))}
        xAxis={xAxis}
      />
      <div style={{ position: "relative", height: 30 }}>
        <Box
          style={{
            width: width - 104 + "px",
            padding: "0 52px",
            display: "flex",
            flexDirection: "row",
            position: "absolute",
            top: "-44px",
            background: theme.palette.secondary.light,
          }}
        >
          {xAxis[0]?.data.map((label) => (
            <div
              style={{
                fontSize: 12,
                padding: "0px 8px",
                width: 100 / xAxis[0]?.data.length + "%",
                textAlign: "center",
              }}
            >
              {label}
            </div>
          ))}
        </Box>
      </div>
    </Box>
  );
};

export default ChartBar;
