import { useTranslation } from "react-i18next";
import { string, object, array } from "yup";

const useValidationSchema = () => {
  const { t } = useTranslation();
  return object({
    firstName: string().required(t("FIELD_REQUIRED")),
    lastName: string().required(t("FIELD_REQUIRED")),
    email: string().email(t("EMAIL_WARNING")).required(t("FIELD_REQUIRED")),
    role: string().required(t("FIELD_REQUIRED")),
  });
};

export default useValidationSchema;
