import { useEffect } from "react";

const RefundFormWrapper = ({
  children,
  shouldResetForm,
  setShouldResetForm,
  resetForm,
}) => {
  useEffect(() => {
    if (shouldResetForm) {
      setShouldResetForm(false);
      resetForm();
    }
  }, [shouldResetForm]);
  return children;
};
export default RefundFormWrapper;
