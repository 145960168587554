import useGetChartWidth from "../../../../hooks/useGetChartWidth";
import {Box, Typography} from "@mui/material";
import theme from "../../../../theme/theme";
import * as React from "react";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import {useState} from "react";
import TableBody from "@mui/material/TableBody";

const ChartGrid = ({title, chartValue, size}) => {
    const width = useGetChartWidth(size);

    const { xAxis, series } = chartValue

    return (
        <Box
            style={{
                marginTop:15,
                width: width + "px",
                backgroundColor: theme.palette.secondary.light,
                borderRadius: 20,
                marginBottom: 15,
                padding: 15
            }}
        >
            <Box>
                <Typography>{title}</Typography>
            </Box>
            <Box>

                <TableContainer
                    component={Paper}
                    sx={{ marginTop: 1 }}
                    variant={"outlined"}
                >
                    <Table
                        sx={{ width: "100%", cursor: "default"}}
                        aria-label="simple table"
                    >
                        <TableHead
                            sx={{
                                backgroundColor: theme.palette.secondary.background,
                                color: theme.palette.text.primary,
                            }}
                        >
                            <TableRow>
                                <TableCell align="center"></TableCell>
                                {xAxis[0]?.data.map((hdr) => (
                                        <TableCell align="center">{hdr}</TableCell>
                                    )
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {series?.map((ser, index) => (
                                <TableRow
                                    key={index}
                                    sx={{ "&:last-child td, &:last-child th": { border: 0 }}}
                                    style={{cursor: "default"}}
                                >
                                    <TableCell align="center">{ser.label}</TableCell>
                                    {ser.data.map((dat) => (
                                        <TableCell align="center">{dat}</TableCell>
                                    ))}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
            </Box>

    );
};
export default ChartGrid;
