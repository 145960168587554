import { useTranslation } from "react-i18next";
import { Box, Chip, Divider, Grid } from "@mui/material";
import { Form, Formik } from "formik";
import FormikTextField from "../../shared/FormikTextField";
import FormikDateTimePickerField from "../../shared/FormikDateTimePickerField";
import React from "react";
import FormikCheckboxField from "../../shared/FormikCheckboxField";

const TransactionForm = ({ handleSubmit, initialValues }) => {
  const { t } = useTranslation();
  const inputProps = { readOnly: true, disabled: true };
  return (
    <Box pb={2} px={4}>
      <Formik
        initialValues={{
          amount: "",
          amountUsed: "",
          apiTraceId: "",
          autoSettle: false,
          body: "",
          card: {
            _3D: "",
            alias: "",
            cardId: "",
            createAliasCVV: false,
            expiryMonth: "",
            expiryYear: "",
            number: "",
          },
          cartId: "",
          clientRequestId: "",
          created: "",
          currency: "",
          currentStatus: "",
          customer: {
            birthDate: "",
            cellPhone: "",
            city: "",
            companyLegalForm: "",
            companyRegisterNumber: "",
            country: "",
            customerId: "",
            email: "",
            firstName: "",
            gender: "",
            ipAddress: "",
            language: "",
            lastName: "",
            name: "",
            phone: "",
            street: "",
            street2: "",
            title: "",
            type: "",
            zipCode: "",
          },
          emsOrderId: "",
          expiration: "",
          generateTicket: false,
          history: [
            {
              actionDate: "",
              actionId: "",
              amount: "",
              description: "",
              transactionStatus: "",
            },
          ],
          language: "",
          merchantId: "",
          option: {
            authenticationOnly: false,
            createAlias: false,
            optionId: "",
            rememberMe: "",
            returnCustomerCountry: false,
            returnMaskedCardNumber: false,
            returnMobileToken: false,
          },
          order: {
            articles: [],
            orderId: "",
            taxAmount: "",
          },
          pap: {
            alias: "",
            createOrder: false,
            displayShippingDetails: false,
            forwardCustomerDetails: false,
            fraudSessionId: "",
            imageUrl: "",
            paypalId: "",
            returnCustomerDetails: false,
            transactionContext: "",
          },
          paymentMethods: [],
          paymentUrl: "",
          redirect: {
            cancelUrl: "",
            errorUrl: "",
            method: "",
            redirectId: "",
            returnTarget: "",
            startTarget: "",
            successUrl: "",
          },
          refno: "",
          refno2: "",
          remainingRefundAmount: "",
          requestStatus: "",
          subMerchantId: "",
          theme: "",
          transactionId: "",
          transactionIdFromEmsResponse: "",
          type: "",
          webhook: "",
          wll: {
            availableBalance: "",
            balance: "",
            currency: "",
            entitlements: "",
            maxBalance: "",
            minBalance: "",
            owner: {
              apartmentNo: "",
              buildingNo: "",
              city: "",
              country: "",
              email: "",
              firstName: "",
              lastName: "",
              ownerId: "",
              postalCode: "",
              street: "",
            },
            preAuthorizedTransactions: [],
            reservedAmount: "",
            status: "",
            userId: "",
            validityEnd: "",
            validityStart: "",
            vatClass: "",
            walletId: "",
          },
          ...initialValues,
        }}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ values }) => (
          <Form>
            <Grid container spacing={1}>
              <Grid item md={3}>
                <FormikDateTimePickerField
                  name={"created"}
                  label={`${t("CREATED")}`}
                  readOnly
                  {...{ inputProps }}
                />
              </Grid>
              {[
                "transactionId",
                "amount",
                "amountUsed",
                "cartId",
                "currency",
                "currentStatus",
                "remainingRefundAmount",
              ].map((v, subIndex) => (
                <Grid item md={3} key={v + subIndex}>
                  <FormikTextField
                    name={v}
                    label={t("transactions." + v)}
                    {...{ inputProps }}
                  />
                </Grid>
              ))}
            </Grid>
            <Grid item md={12} marginY={4}>
              <Divider>
                <Chip label={t("DETIALS")} />
              </Divider>
            </Grid>
            <Grid container spacing={1}>
              <Grid item md={1.5}>
                <FormikTextField
                  name={"card.cardId"}
                  label={t("transactions.cardId")}
                  {...{ inputProps }}
                />
              </Grid>
              <Grid item md={1.5}>
                <FormikTextField
                  name={"customer.customerId"}
                  label={t("transactions.customerId")}
                  {...{ inputProps }}
                />
              </Grid>
              <Grid item md={1.5}>
                <FormikTextField
                  name={"option.optionId"}
                  label={t("transactions.optionId")}
                  {...{ inputProps }}
                />
              </Grid>
              <Grid item md={1.5}>
                <FormikTextField
                  name={"order.orderId"}
                  label={t("transactions.orderId")}
                  {...{ inputProps }}
                />
              </Grid>
              <Grid item md={1.5}>
                <FormikTextField
                  name={"pap.paypalId"}
                  label={t("transactions.paypalId")}
                  {...{ inputProps }}
                />
              </Grid>
              <Grid item md={1.5}>
                <FormikTextField
                  name={"redirect.redirectId"}
                  label={t("transactions.redirectId")}
                  {...{ inputProps }}
                />
              </Grid>
              <Grid item md={1.5}>
                <FormikTextField
                  name={"wll.walletId"}
                  label={t("transactions.walletId")}
                  {...{ inputProps }}
                />
              </Grid>
              <Grid item md={1.5}>
                <FormikTextField
                  name={"apiTraceId"}
                  label={t("transactions.apiTraceId")}
                  {...{ inputProps }}
                />
              </Grid>
              {[
                "transactionIdFromEmsResponse",
                "webhook",
                "clientRequestId",
                "emsOrderId",
                "expiration",
                "language",
                "merchantId",
                "paymentUrl",
                "refno",
                "refno2",
                "requestStatus",
                "subMerchantId",
                "theme",
              ].map((v, subIndex) => (
                <Grid item md={3} key={v + subIndex}>
                  <FormikTextField
                    name={v}
                    label={t("transactions." + v)}
                    {...{ inputProps }}
                  />
                </Grid>
              ))}

              <Grid item md={3} mt={2} ml={2}>
                <FormikCheckboxField
                  name={"generateTicket"}
                  label={`${t("transactions.generateTicket")}`}
                  disabled
                />
              </Grid>
              <Grid item md={3} mt={2}>
                <FormikCheckboxField
                  name={"autoSettle"}
                  label={`${t("transactions.autoSettle")}`}
                  disabled
                />
              </Grid>

              <Grid item md={12}>
                <FormikTextField
                  name={"paymentMethods"}
                  label={`${t("transactions.paymentMethods")}`}
                  value={values?.paymentMethods?.join(", ")}
                  {...{ inputProps }}
                />
              </Grid>

              <Grid item md={12}>
                <FormikTextField
                  name={"body"}
                  label={`${t("transactions.body")}`}
                  multiline
                  {...{ inputProps }}
                />
              </Grid>
            </Grid>

            <Box py={0.5} />
          </Form>
        )}
      </Formik>
    </Box>
  );
};
export default TransactionForm;
