import { create } from "zustand";

const useCustomersStore = create(
    (set) => ({
        pageType: "LIST",
        customer: {},
        showCustomerDetails: (customer) =>
            set(() => ({ pageType: "DETAILS", customer })),
        showCustomersList: () => set(() => ({ pageType: "LIST", user: {} })),
    }),
    {
        name: "customersStore",
    }
);

export default useCustomersStore;
