import React from "react";
import Modal from "../../shared/Modal";
import UserForm from "./UserForm";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { useMutation, useQueryClient } from "react-query";
import { editUser, resetPassword } from "../../../api";
import queryKeys from "../../../api/queryKeys";

const EditUserModal = ({ onClose, open, userBeingEdited }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const { mutate: handleEditUser, isLoading } = useMutation(
    (user) => editUser(user, userBeingEdited.id),
    {
      onSuccess: () => {
        onClose();
        enqueueSnackbar(t("UPDATE_SUCCESSFULLY"), { variant: "success" });
        queryClient.invalidateQueries({ queryKey: [queryKeys.USERS] });
      },
      onError: () => {
        enqueueSnackbar(t("ERROR_DEFAULT"), { variant: "error" });
      },
    }
  );

  const { mutate: handleResetPassword, isLoading: isLoadingResetPassword } =
    useMutation((id) => resetPassword(id), {
      onSuccess: () => {
        enqueueSnackbar(t("RESET_PASSWORD_SUCCESS"), { variant: "success" });
        queryClient.invalidateQueries({ queryKey: [queryKeys.USERS] });
      },
      onError: () => {
        enqueueSnackbar(t("ERROR_DEFAULT"), { variant: "error" });
      },
    });

  return (
    <Modal onClose={onClose} open={open} title={t("EDIT_ADMIN")}>
      <UserForm
        handleSubmit={(values) => handleEditUser(values)}
        isLoading={isLoading}
        buttonText={t("EDIT_USER")}
        initialValues={userBeingEdited}
        edit
        editLoading={isLoadingResetPassword}
        handleResetPassword={() => handleResetPassword(userBeingEdited.id)}
      />
    </Modal>
  );
};

export default EditUserModal;
