import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import React from "react";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCellWithSort from "../../shared/TableCellWithSort";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import StandardTableCell from "../../shared/StandardTableCell";
import IconButton from "@mui/material/IconButton";
import DownloadIcon from "@mui/icons-material/Download";
import { useQuery } from "react-query";
import queryKeys from "../../../api/queryKeys";
import { fetchReportByName, fetchReports } from "../../../api";
import { TWO_MINUTES } from "../../../constants";
import StyledTable from "../../shared/StyledTable";

const ReportTable = ({ loading, reports, query, handleSort }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { sortType, sortBy } = query;

  function getReportByName(fileName) {
    let result = fetchReportByName(fileName);
    result.then((res) => {
      var url = window.URL.createObjectURL(res.data);
      var a = document.createElement("a");
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      a.remove();
    });
  }

  return (
    <StyledTable loading={loading}>
      <TableHead
        sx={{
          backgroundColor: theme.palette.secondary.background,
          color: theme.palette.text.primary,
        }}
      >
        <TableRow>
          <TableCellWithSort
            sortOrder={sortType}
            handleSortClick={() => handleSort("name")}
            displayedText={t("NAME")}
            selected={sortBy === "name"}
            width={"30%"}
          />
          <TableCellWithSort
            sortOrder={sortType}
            handleSortClick={() => handleSort("date")}
            displayedText={t("PERIOD")}
            selected={sortBy === "date"}
          />
          <TableCell align="center"></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {reports?.map((row) => (
          <TableRow
            key={row.name}
            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
          >
            <StandardTableCell content={row.fileName} />
            <StandardTableCell content={row.period} />
            <TableCell align="center">
              <IconButton aria-label="delete" size="large">
                <DownloadIcon onClick={() => getReportByName(row.fileName)} />
              </IconButton>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </StyledTable>
  );
};
export default ReportTable;
