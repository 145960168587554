import { create } from "zustand";

const useUserStore = create(
  (set, get) => ({
    user: undefined,
    adminRoles: ["ROLE_ADMIN"],
    isAdmin: () => {
      return true;
      // const user = get().user;
      // const adminRoles = get().adminRoles;
      // return user && user.roles.some((role) => adminRoles.includes(role.name));
    },
    setUser: (user) => set(() => ({ user })),
    resetUser: () => set(() => ({ user: undefined })),
  }),
  {
    name: "userStore",
  }
);

export default useUserStore;
