import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import { sortType, TWO_MINUTES } from "../../../constants";
import TableTitle from "../../shared/TableTitle";
import { useQuery } from "react-query";
import queryKeys from "../../../api/queryKeys";
import { fetchTransactions } from "../../../api";
import PaginationComponent from "../../shared/PaginationComponent";
import TransactionTable from "./TransactionTable";
import handleTableSort from "../../../utils/handleTableSort";

const Transactions = () => {
  const { t } = useTranslation();
  const [query, setQuery] = useState({
    page: 0,
    size: 10,
    sortBy: "id",
    sortType: sortType.asc,
  });
  const { data: transactions, isLoading } = useQuery(
    [queryKeys.TRANSACTIONS, query],
    () =>
      fetchTransactions({ ...query, pageNo: query.page, pageSize: query.size }),
    { staleTime: TWO_MINUTES }
  );

  return (
    <>
      <TableTitle title={t("TRANSACTIONS")} />
      <TransactionTable
        transactions={transactions?.data.content}
        loading={isLoading}
        query={query}
        handleSort={(sortBy) => handleTableSort(sortBy, query, setQuery)}
      />
      {transactions?.data && (
        <PaginationComponent
          totalPages={transactions.data.totalPages}
          number={transactions.data.number}
          size={transactions.data.size}
          setQuery={setQuery}
          query={query}
        />
      )}
    </>
  );
};

export default Transactions;
