const queryKeys = {
  USERS: "users",
  LANGUAGES: "languages",
  PRODUCTS: "products",
  PRODUCT: "product",
  PRODUCT_GROUPS: "productGroups",
  APP_INFO: "appInfo",
  ALLOWED_ROLES: "allowedRoles",
  CONFIGURATION: "configuration ",
  CHARTS: "charts",
  CHARTS_PERIOD: "chartsPeriod",
  TICKETS: "tickets",
  TICKETS_REFUNDS: "ticketsRefunds",
  CUSTOMER: "customer",
  REPORTS: "Reports",
  TRANSACTIONS: "Transactions",
};

export default queryKeys;
