import * as React from "react";
import ProductForm from "./ProductForm";
import Modal from "../../shared/Modal";
import { useTranslation } from "react-i18next";

const EditProductModal = ({ onClose, open, productBeingEdited }) => {
  const handleSubmit = () => {};
  const { t } = useTranslation();

  return (
    <Modal
      onClose={onClose}
      open={open}
      title={t("PRODUCT_DETAIL")}
      fullWidth
      maxWidth={"md"}
    >
      <ProductForm
        handleSubmit={handleSubmit}
        initialValues={productBeingEdited}
      />
    </Modal>
  );
};

export default EditProductModal;
