import { object, array, string, number } from "yup";
import { useTranslation } from "react-i18next";

const useProductFormValidationSchema = () => {
  const { t } = useTranslation();
  return object({
    productTranslations: array().of(
      object({
        language: string(),
        description: string(),
      })
    ),
    productGroupId: number().required(t("REQUIRED")),
  });
};

export { useProductFormValidationSchema };
