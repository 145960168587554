import { FormControl, FormHelperText, TextField } from "@mui/material";
import { Field, useField } from "formik";
import React from "react";

const MuiSelectField = ({ children, form, field, ...formikProps }) => {
  const { name, value } = field;
  const { setFieldValue } = form;
  return (
    <TextField
      select
      name={name}
      value={value}
      onChange={(e) => {
        setFieldValue(name, e.target.value);
      }}
      label={formikProps?.label}
    >
      {children}
    </TextField>
  );
};

const FormikSelectField = ({ children, name, ...props }) => {
  const [_, meta] = useField(name);
  return (
    <FormControl fullWidth sx={{ py: 1 }} {...props}>
      <Field
        name={name}
        component={(formikProps) => (
          <MuiSelectField {...formikProps} {...props}>
            {children}
          </MuiSelectField>
        )}
      ></Field>
      {meta.touched && meta.error && (
        <FormHelperText error>{meta.error}</FormHelperText>
      )}
    </FormControl>
  );
};

export default FormikSelectField;
