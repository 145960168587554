import React, { useState } from "react";
import { useQuery } from "react-query";
import { fetchConfiguration } from "../../../api";
import { sortType, TWO_MINUTES } from "../../../constants";
import { useTranslation } from "react-i18next";
import PaginationComponent from "../../shared/PaginationComponent";
import handleTableSort from "../../../utils/handleTableSort";
import SearchTableComponent from "../../shared/SearchTableComponent";
import handleEnterPress from "../../../utils/handleEnterPress";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import { Box } from "@mui/material";
import ConfigurationTable from "./ConfigurationTable";
import AddConfigurationModal from "./AddConfigurationModal";
import queryKeys from "../../../api/queryKeys";
import TableTitle from "../../shared/TableTitle";
import useTokensState from "../../../hooks/useTokensState";

const Configuration = () => {
  const { t } = useTranslation();
  const [addConfigurationModalVisible, setAddConfigurationModalVisible] =
    useState(false);
  const [searchText, setSearchText] = useState("");
  const [query, setQuery] = useState({
    page: 0,
    size: 10,
    searchText: "",
    sortBy: "id",
    sortType: sortType.asc,
  });

  const { data: configuration, isLoading } = useQuery(
    [queryKeys.CONFIGURATION, query],
    () => fetchConfiguration(query),
    { staleTime: TWO_MINUTES }
  );

  const handleSearch = () => setQuery({ ...query, page: 0, searchText });
    const {getRole} = useTokensState();

  return (
    <>
      <TableTitle title={t("CONFIGURATION")} />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <SearchTableComponent
          handleSearch={handleSearch}
          handleChange={(e) => setSearchText(e.target.value)}
          handleKeyDown={(e) => handleEnterPress(e, handleSearch)}
        />
          {getRole() === 'SUPER_ADMIN' || getRole() === 'ADMIN' && (
              <Button
                  variant="contained"
                  endIcon={<AddIcon />}
                  onClick={() => setAddConfigurationModalVisible(true)}
              >
                  {t("ADD")}
              </Button>
          )}

      </Box>
      <ConfigurationTable
        configuration={configuration?.data.content}
        loading={isLoading}
        query={query}
        handleSort={(sortBy) => handleTableSort(sortBy, query, setQuery)}
      />
      {configuration?.data && (
        <PaginationComponent
          totalPages={configuration.data.totalPages}
          number={configuration.data.number}
          size={configuration.data.size}
          setQuery={setQuery}
          query={query}
        />
      )}
      <AddConfigurationModal
        onClose={() => setAddConfigurationModalVisible(false)}
        open={addConfigurationModalVisible}
      />
    </>
  );
};

export default Configuration;
