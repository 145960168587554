import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import { sortType, TWO_MINUTES } from "../../../constants";
import TableTitle from "../../shared/TableTitle";
import { Box } from "@mui/material";
import SearchTableComponent from "../../shared/SearchTableComponent";
import handleEnterPress from "../../../utils/handleEnterPress";
import { useQuery } from "react-query";
import queryKeys from "../../../api/queryKeys";
import { fetchConfiguration, fetchTicket } from "../../../api";
import PaginationComponent from "../../shared/PaginationComponent";
import TicketTable from "./TicketTable";
import handleTableSort from "../../../utils/handleTableSort";

const Tickets = ({ customerId, isShowingInTicket }) => {
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState("");
  const [query, setQuery] = useState({
    page: 0,
    size: 10,
    searchText: "",
    sortBy: "id",
    sortType: sortType.asc,
    userId: customerId !== undefined ? customerId : null,
  });
  const { data: ticket, isLoading } = useQuery(
    [queryKeys.TICKETS, query],
    () => fetchTicket(query),
    { staleTime: TWO_MINUTES }
  );

  const handleSearch = () => setQuery({ ...query, page: 0, searchText });
  return (
    <>
      {" "}
      {customerId === undefined ? <TableTitle title={t("TICKETS")} /> : null}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <SearchTableComponent
          handleSearch={handleSearch}
          handleChange={(e) => setSearchText(e.target.value)}
          handleKeyDown={(e) => handleEnterPress(e, handleSearch)}
          buttonText={t("SEARCH_TICKET_NUMBER")}
        />
      </Box>
      <TicketTable
        ticket={ticket?.data.content}
        loading={isLoading}
        query={query}
        handleSort={(sortBy) => handleTableSort(sortBy, query, setQuery)}
        isShowingInTicket={true}
      />
      {ticket?.data && (
        <PaginationComponent
          totalPages={ticket.data.totalPages}
          number={ticket.data.number}
          size={ticket.data.size}
          setQuery={setQuery}
          query={query}
        />
      )}
    </>
  );
};

export default Tickets;
