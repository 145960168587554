import { useState, createContext } from "react";

const AppContext = createContext(undefined);

const AppProvider = ({ children }) => {
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [refreshToken, setRefreshToken] = useState(
    localStorage.getItem("refreshToken")
  );

  const setTokenState = (newToken) => {
    setToken(newToken);
    localStorage.setItem("token", newToken);
  };
  const setRefreshTokenState = (newRefreshToken) => {
    setRefreshToken(newRefreshToken);
    localStorage.setItem("refreshToken", newRefreshToken);
  };

  return (
    <AppContext.Provider
      value={{
        tokenState: [token, setTokenState],
        refreshTokenState: [refreshToken, setRefreshTokenState],
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export { AppContext, AppProvider };
