import React from "react";
import { useField } from "formik";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import FormikTextField from "./FormikTextField";
import { Box } from "@mui/material";
import { YEAR_MONTH_DAY } from "../../constants";
import CloseIcon from "@mui/icons-material/Close";

const FormikDatePickerField = ({
  name,
  label,
  readOnly,
  minDateTime,
  maxDateTime,
  ...rest
}) => {
  const [field, _, helpers] = useField(name);
  const { value } = field;
  return (
    <Box {...rest}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateTimePicker
          label={label}
          onChange={(pickedValue) =>
            helpers.setValue(
              pickedValue && dayjs(pickedValue).format(YEAR_MONTH_DAY)
            )
          }
          value={value}
          renderInput={(params) => (
            <FormikTextField
              name={name}
              {...params}
              inputProps={{
                ...params.inputProps,
                value: value ? params.inputProps.value : "",
              }}
            />
          )}
          readOnly={readOnly}
          minDateTime={minDateTime}
          maxDateTime={maxDateTime}
        />
        {value && !readOnly && (
          <div
            style={{ position: "relative", cursor: "pointer" }}
            onClick={() => helpers.setValue(undefined)}
          >
            <CloseIcon
              style={{
                position: "absolute",
                bottom: 24,
                right: 36,
                color: "#D60409",
              }}
            />
          </div>
        )}
      </LocalizationProvider>
    </Box>
  );
};

export default FormikDatePickerField;
