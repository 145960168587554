import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import translationEN from "./assets/locales/en/translation.json";
import translationDE from "./assets/locales/de/translation.json";
import moment from "moment";

const fallbackLng = ["en"];
const availableLanguages = ["en"];

const resources = {
  en: {
    translation: translationEN,
  },
  de: {
    translation: translationDE,
  },
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng,

    detection: {
      checkWhitelist: true,
    },
    supportedLngs: ["en", "de"],
    debug: false,

    whitelist: availableLanguages,

    interpolation: {
      formatSeparator: ",",
      format: function (value, formatting, lng) {
        if (value instanceof Date) return moment(value).format(formatting);
        if (formatting.includes("separator"))
          return parseFloat(value)
            .toFixed(2)
            .replaceAll(".", formatting.replaceAll("separator=", ""));

        return value;
      },
    },
  });

export default i18n;
