import { useTranslation } from "react-i18next";
import TableTitle from "../../shared/TableTitle";
import React, { useState } from "react";
import ReportTable from "./ReportTable";
import { sortType } from "../../../constants";
import { useQuery } from "react-query";
import queryKeys from "../../../api/queryKeys";
import { fetchReports, refreshReports } from "../../../api";
import handleTableSort from "../../../utils/handleTableSort";
import PaginationComponent from "../../shared/PaginationComponent";
import Button from "@mui/material/Button";
import { Box, Grid } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import SearchTableComponent from "../../shared/SearchTableComponent";
import handleEnterPress from "../../../utils/handleEnterPress";

const Reports = () => {
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState("");
  const [query, setQuery] = useState({
    page: 0,
    size: 10,
    searchText: "",
    sortBy: "name",
    sortType: sortType.desc,
  });

  const {
    data: reports,
    isLoading,
    refetch,
    isRefetching,
  } = useQuery([queryKeys.REPORTS, query], () => fetchReports(query), {
    staleTime: 0,
  });
  function refreshData() {
    refreshReports().finally(() => {
      refetch().finally(() => {});
    });
  }

  const handleSearch = () => setQuery({ ...query, page: 0, searchText });

  return (
    <>
      <TableTitle title={t("REPORTS")} />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: 1,
        }}
      >
        <SearchTableComponent
          handleSearch={handleSearch}
          handleChange={(e) => setSearchText(e.target.value)}
          handleKeyDown={(e) => handleEnterPress(e, handleSearch)}
          buttonText={t("SEARCH")}
        />
        <Grid item xs={2}>
          <Button
            variant="contained"
            endIcon={<RefreshIcon />}
            onClick={() => refreshData()}
          >
            {t("REFRESH")}
          </Button>
        </Grid>
      </Box>
      <ReportTable
        reports={reports?.data.content}
        loading={isLoading || isRefetching}
        query={query}
        handleSort={(sortBy) => handleTableSort(sortBy, query, setQuery)}
      />
      {reports?.data && (
        <PaginationComponent
          totalPages={reports.data.totalPages}
          number={reports.data.number}
          size={reports.data.size}
          setQuery={setQuery}
          query={query}
        />
      )}
    </>
  );
};
export default Reports;
