import React from "react";
import { useTranslation } from "react-i18next";
import {
  FormControl,
  Grid,
  InputLabel,
  Pagination,
  Select,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import { PAGINATION_SIZES } from "../../constants";

const PaginationComponent = ({ totalPages, number, size, setQuery, query }) => {
  const { t } = useTranslation();
  return (
    <Grid container style={{ marginTop: 10 }}>
      <Grid item xs={4}>
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
          <InputLabel id="demo-select-small">{t("PAGE_SIZE")}</InputLabel>
          <Select
            labelId="demo-select-small"
            id="demo-select-small"
            value={size}
            label={t("PAGE_SIZE")}
            onChange={(e) =>
              setQuery({ ...query, page: 0, size: e.target.value })
            }
          >
            {PAGINATION_SIZES.map((size) => (
              <MenuItem value={size} key={size}>
                {size}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={8}>
        <Box display="flex" justifyContent="flex-end" style={{ marginTop: 10 }}>
          <Pagination
            count={totalPages}
            variant="outlined"
            shape="rounded"
            page={number + 1}
            onChange={(e, value) => setQuery({ ...query, page: value - 1 })}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default PaginationComponent;
