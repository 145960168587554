import { useTranslation } from "react-i18next";
import * as React from "react";
import { Form, Formik } from "formik";
import { Box, Button, Grid } from "@mui/material";
import FormikTextField from "../../shared/FormikTextField";
import { useSnackbar } from "notistack";
import { useMutation, useQueryClient } from "react-query";
import { refundTicket } from "../../../api";
import queryKeys from "../../../api/queryKeys";
import useValidationSchema from "./validation";
import FormikCheckboxField from "../../shared/FormikCheckboxField";
import FormikSelectField from "../../shared/FormikSelectField";
import MenuItem from "@mui/material/MenuItem";
import { JUSTFICATIONS, REFUND_CATEGORIES } from "../../../constants";
import RefundFormWrapper from "./RefundFormWrapper";
import { useState } from "react";
import useTokensState from "../../../hooks/useTokensState";

const RefundForm = ({ ticketNumber, refunds }) => {
  const { enqueueSnackbar } = useSnackbar();
  const validationSchema = useValidationSchema();
  const queryClient = useQueryClient();
  const [shouldResetForm, setShouldResetForm] = useState(false);
  const { token } = useTokensState();

  const { mutate: handleRefund, isLoading } = useMutation(
    (values) => refundTicket({ ...values, token, ticketNumber }),
    {
      onSuccess: () => {
        setShouldResetForm(true);
        enqueueSnackbar(t("REFUNDED"), { variant: "success" });
        queryClient.invalidateQueries({
          queryKey: [queryKeys.TICKETS_REFUNDS],
        });
        queryClient.invalidateQueries({
          queryKey: [queryKeys.TICKETS],
        });
      },
      onError: (err) => {
        enqueueSnackbar(err?.response?.data?.message || t("ERROR_DEFAULT"), {
          variant: "error",
        });
      },
    }
  );

  const { t } = useTranslation();
  const disabledInvalidateTicket =
    refunds?.find((r) => r.invalidateTicket) !== undefined;

  return (
    <Box pb={2}>
      <Formik
        initialValues={{
          amount: 0,
          comment: "",
          invalidateTicket: false,
        }}
        onSubmit={handleRefund}
        enableReinitialize
        validationSchema={validationSchema}
      >
        {({ handleSubmit, resetForm }) => (
          <Form>
            <RefundFormWrapper
              {...{ resetForm, shouldResetForm, setShouldResetForm }}
            >
              <Grid container spacing={1}>
                <Grid item sm={12} md={12}>
                  <FormikTextField
                    name={"amount"}
                    label={`${t("amount")}`}
                    type="number"
                  />
                </Grid>
                <Grid item sm={12} md={12}>
                  <FormikCheckboxField
                    name={"invalidateTicket"}
                    label={`${t("invalidateTicket")}`}
                    disabled={disabledInvalidateTicket}
                  />
                </Grid>
                <Grid item sm={12} md={12}>
                  <FormikSelectField
                    options={[]}
                    name={"category"}
                    label={t("refunds.category")}
                  >
                    {REFUND_CATEGORIES.map((value) => (
                      <MenuItem value={value} key={value}>
                        {t("refundCategories." + value)}
                      </MenuItem>
                    ))}
                  </FormikSelectField>
                </Grid>
                <Grid item sm={12} md={12}>
                  <FormikSelectField
                    options={[]}
                    name={"justification"}
                    label={t("refunds.justification")}
                  >
                    {JUSTFICATIONS.map((value) => (
                      <MenuItem value={value} key={value}>
                        {t("justifications." + value)}
                      </MenuItem>
                    ))}
                  </FormikSelectField>
                </Grid>
                <Grid item sm={12} md={12}>
                  <FormikTextField
                    name={"comment"}
                    label={`${t("comment")}`}
                    maxRows={4}
                    multiline
                  />
                </Grid>
              </Grid>

              <Box py={2}>
                <Button
                  disabled={isLoading}
                  color="secondary"
                  variant="contained"
                  fullWidth
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  {t("REFUND")}
                </Button>
              </Box>
            </RefundFormWrapper>
          </Form>
        )}
      </Formik>
    </Box>
  );
};
export default RefundForm;
