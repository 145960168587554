import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Form, Formik } from "formik";
import FormikTextField from "../shared/FormikTextField";
import { Box, Button, Typography } from "@mui/material";
import { loginApi } from "../../api";
import { useSnackbar } from "notistack";
import { useLoginValidationSchema } from "./validation";
import useTokensState from "../../hooks/useTokensState";

const LoginForm = ({ history, handlePasswordForgotClick }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const validationSchema = useLoginValidationSchema();
  const [isLoading, setIsLoading] = useState(false);
  const { token, setToken, setRefreshToken } = useTokensState();
  const handleLogin = async ({ login, password }) => {
    setIsLoading(true);
    try {
      const res = await loginApi(login, password);
      if (res.status === 200) {
        setToken(res.data.accessToken);
        setRefreshToken(res.data.refreshToken);
      }
    } catch (error) {
      console.log(error.response);
      enqueueSnackbar(t("INCORRECT_LOGIN_OR_PASSWORD"), { variant: "error" });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (token) history.push("/admin-panel");
  }, [token]);

  return (
    <Box>
      <Typography variant="h4" style={{ marginBottom: 20 }}>
        {t("LOGIN")}
      </Typography>
      <Formik
        initialValues={{
          login: "",
          password: "",
        }}
        onSubmit={handleLogin}
        validationSchema={validationSchema}
      >
        <Form>
          <FormikTextField name={"login"} label={`${t("EMAIL")} *`} />
          <FormikTextField
            name={"password"}
            label={`${t("PASSWORD")} *`}
            type={"password"}
            boxProps={{ sx: { width: { sm: 500 } } }}
          />
          <Button
            color="primary"
            size="small"
            onClick={handlePasswordForgotClick}
            variant="text"
            type={"button"}
            sx={{ mt: -1 }}
          >
            {t("PASSWORD_FORGOT")}
          </Button>
          <Button
            color="secondary"
            variant="contained"
            fullWidth
            type="submit"
            disabled={isLoading}
          >
            {t("LOGIN")}
          </Button>
        </Form>
      </Formik>
    </Box>
  );
};

export default LoginForm;
