import React, { useState } from "react";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Divider } from "@mui/material";
import packageJson from "../../../package.json";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import useUserStore from "../../hooks/useUserStore";
import { useQuery } from "react-query";
import queryKeys from "../../api/queryKeys";
import { fetchAppInfo, logout } from "../../api";
import ChangePasswordModal from "./ChangePasswordModal";
import useTokensState from "../../hooks/useTokensState";

const UserMenu = ({ anchorElUser, setAnchorElUser, history }) => {
  const { t } = useTranslation();
  const { user } = useUserStore();
  const { token, cleanTokens } = useTokensState();
  const { data: appInfo } = useQuery(
    [queryKeys.APP_INFO],
    () => fetchAppInfo(),
    { staleTime: Infinity }
  );
  const [changePasswordModalVisible, setChangePasswordModalVisible] =
    useState(false);
  const handleOnClickLogoutMenu = async () => {
    setAnchorElUser(null);
    try {
      const res = await logout();
      if (res.status === 204) {
        cleanTokens();
        window.location = "/login";
      }
    } catch (error) {
      console.log(error.response);
    }
  };
  return (
    <Box sx={{ flexGrow: 0 }}>
      <Tooltip title="Open settings">
        <IconButton
          onClick={(e) => setAnchorElUser(e.currentTarget)}
          sx={{ p: 0 }}
        >
          <Avatar alt={user ? user.firstName : ""} src="/" />
        </IconButton>
      </Tooltip>
      <Menu
        sx={{ mt: "45px" }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorElUser)}
        onClose={() => setAnchorElUser(null)}
      >
        <div>
          {/*{user?.roles?.map((role) => (*/}
          {/*  <MenuItem*/}
          {/*    key={role.id}*/}
          {/*    style={{ paddingTop: 0, paddingBottom: 0 }}*/}
          {/*    disabled*/}
          {/*  >*/}
          {/*    <div style={{ fontSize: 12 }}>{t(role.name)}</div>*/}
          {/*  </MenuItem>*/}
          {/*))}*/}
          <Divider />
          <MenuItem
            key={"version"}
            style={{ paddingTop: 0, paddingBottom: 0, fontSize: 12 }}
            disabled
          >
            frontend v{packageJson.version}
            <br />
            backend v{appInfo?.data?.microserviceVersion}
          </MenuItem>
          <Divider />
          {!token ? (
            <MenuItem
              key={"login"}
              onClick={() => {
                setAnchorElUser(null);
                history.push("/login");
              }}
            >
              <Typography textAlign="center">{t("LOGIN")}</Typography>
            </MenuItem>
          ) : (
            <>
              <MenuItem
                key={"changePassword"}
                onClick={() => setChangePasswordModalVisible(true)}
              >
                <Typography textAlign="center">
                  {t("CHANGE_PASSWORD")}
                </Typography>
              </MenuItem>
              <MenuItem key={"logout"} onClick={handleOnClickLogoutMenu}>
                <Typography textAlign="center">{t("LOGOUT")}</Typography>
              </MenuItem>
            </>
          )}
        </div>
      </Menu>
      <ChangePasswordModal
        open={changePasswordModalVisible}
        onClose={() => setChangePasswordModalVisible(false)}
      />
    </Box>
  );
};

export default UserMenu;
