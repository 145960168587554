import { useTranslation } from "react-i18next";
import { string, object, ref } from "yup";

const useValidationSchema = () => {
  const { t } = useTranslation();
  return object({
    oldPassword: string().required(t("FIELD_REQUIRED")),
    newPassword: string()
      .min(8, t("MIN_LENGTH_WARNING"))
      .required(t("FIELD_REQUIRED")),
    newPasswordRepeat: string()
      .required(t("FIELD_REQUIRED"))
      .oneOf([ref("newPassword")], t("NEW_PASSWORD_DO_NOT_MATCH")),
  });
};

export default useValidationSchema;
