import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import LanguageSelect from "../language/LanguageSelect";
import { useTheme } from "@mui/material";
import { withRouter } from "react-router-dom";
import useUserStore from "../../hooks/useUserStore";
import UserMenu from "./UserMenu";

const ResponsiveAppBar = (props) => {
  const theme = useTheme();
  const { user } = useUserStore();
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  return (
    <AppBar
      position="static"
      style={{
        backgroundColor: theme.palette.secondary.background,
        color: theme.palette.text.primary,
      }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 1, display: "flex", height: 44 }}>
            <img src="logo.svg" alt="logo" loading="lazy" />
          </Box>
          <Box sx={{ mt: 0.5 }}>
            <LanguageSelect />
          </Box>
          <UserMenu
            setAnchorElUser={setAnchorElUser}
            anchorElUser={anchorElUser}
            history={props.history}
          />
          <Box sx={{ ml: 3, fontSize: 14, mb: 0.5 }}>{user?.login}</Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default withRouter(ResponsiveAppBar);
