import React from "react";
import { useTranslation } from "react-i18next";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import { useTheme } from "@mui/material";

const LanguageSelect = () => {
  const { i18n } = useTranslation("home");
  const theme = useTheme();
  const interfaceLanguages = [{ code: "de" }, { code: "en" }];

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (e) => {
    setAnchorEl(null);
    i18n.changeLanguage(e.target.getAttribute("value"));
  };

  return (
    <>
      <Button
        style={{ color: theme.palette.text.primary }}
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        {i18n.language}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {interfaceLanguages.map((language) => (
          <MenuItem
            key={language.code}
            onClick={handleClose}
            value={language.code}
          >
            {language.code.toUpperCase()}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default LanguageSelect;
