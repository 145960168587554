import axios from "axios";
import environment from "../assets/environment.json";
import i18next from "i18next";
//TODO move all api-related stuff here (endpoints + intenceptors from Loader)

export const api = axios.create({
  baseURL: environment.backendURL,
});
export const apiToken = axios.create({
  baseURL: environment.backendURL,
});
export const apiV3 = axios.create({
  baseURL: environment.backendURLV3,
  headers: {
    Authorization: "Basic Ym10LXNlcnZpY2U6dTglMTBLI2VoYXht",
  },
});
api.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
api.defaults.withCredentials = true;
apiToken.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
apiToken.defaults.withCredentials = true;
apiV3.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
apiV3.defaults.withCredentials = true;

export const refreshTokenApi = (refreshToken) =>
  apiToken.post("/auth/refresh", {
    refreshToken,
  });

export const loginApi = (login, password) =>
  api.post("/auth/login", {
    login,
    password,
  });

export const logout = () => api.post("/auth/logout");

export const resetEmailPassword = (email) =>
  api.post(`/admin-user/password/email-reset`, { email: email });

export const fetchUsers = (query) =>
  api.get("/admin-user", {
    params: query,
  });

export const fetchUserRoles = () => api.get("/admin-user/roles");

export const addUser = (body) => api.post("/admin-user", body);

export const changePassword = (body) =>
  api.post("/admin-user/password/change", body);

export const editUser = (body, userId) =>
  api.put("/admin-user/" + userId, body);

export const resetPassword = (id) =>
  api.post("/admin-user/password/id-reset/" + id);

export const fetchProducts = (query) => {
  return api.get("/product", {
    params: query,
  });
};

export const editProduct = ({ id, ...rest }) => api.put("/product/" + id, rest);

export const addProduct = (body) => api.post("/product", body);

export const fetchProductGroups = (query) => {
  return api.get("/product-group", {
    params: query,
  });
};

export const editProductGroup = ({ id, ...rest }) =>
  api.put("/product-group/" + id, rest);

export const addProductGroup = (body) => api.post("/product-group", body);

export const fetchAppInfo = () => api.get("/version");

export const fetchConfiguration = (query) => {
  return api.get("/configuration", {
    params: query,
  });
};
export const addConfiguration = (body) => api.post("/configuration", body);

export const editConfiguration = (body) =>
  api.put("/configuration/" + body.id, body);

export const deleteConfiguration = (id) => api.delete(`/configuration/${id}`);

export const fetchCharts = ({ query, signal }) => {
  return api.get("/charts", { signal, params: query });
};
export const fetchTicket = (query) => {
  return api.get("/ticket", {
    params: query,
  });
};

export const fetchCustomer = (query) => {
  return api.get("/user", {
    params: query,
  });
};
export const fetchCustomerById = (id) => {
  return api.get("/user/" + id);
};
export const fetchChartsPeriods = ({ signal }) =>
  api.get("/charts/period", { signal });
export const fetchReports = (query) => {
  return api.get("/reports", {
    params: query,
  });
};
export const refreshReports = () => api.delete("/reports/cache");
export const fetchReportByName = (name) => {
  return api.get("/reports/download/" + name, { responseType: "blob" });
};
export const refundTransaction = ({ transactionId, ...body }) =>
  apiV3.post(`/v3/payment/transactions/${transactionId}/refund`, body);

export const refundTicket = (body) => apiV3.post("/v3/ticket/refunds", body);

export const editRefundTicket = ({ refundId, ...body }) =>
  apiV3.patch("/v3/ticket/refunds/patch/" + refundId, body);

export const fetchTicketRefunds = ({ ticketNumber, ...query }) =>
  apiV3.get("/v3/ticket/refunds/" + ticketNumber, {
    params: query,
  });

export const fetchTransactions = (query) =>
  apiV3.get("/v3/payment/service/transaction", {
    params: query,
  });

function onFulfilledRequest(config) {
  let token = localStorage.getItem("token");
  config.headers["Accept-Language"] = i18next.language;
  if (token !== null) {
    config.headers["Authorization"] = `Bearer ${token}`;
  } else {
    let refreshToken = localStorage.getItem("refreshToken");
    refreshTokenApi(refreshToken)
      .then((res) => {
        localStorage.setItem("token", res.data.accessToken);
        localStorage.setItem("refreshToken", res.data.refreshToken);
        config.headers["Authorization"] = `Bearer ${res.data.accessToken}`;
        location.reload();
      })
      .catch((reason) => {
        if (401 === reason?.response?.status) {
          localStorage.clear();
          location.reload();
        }
      });
  }
  config.withCredentials = true;
  return config;
}

function onRejectedRequest(error) {
  return Promise.reject(error);
}

function onFulfilledResponse(response) {
  return response;
}

function onRejectedResponse(error) {
  let refreshToken = localStorage.getItem("refreshToken");
  let token = localStorage.getItem("token");
  if (401 === error.response.status && token) {
    refreshTokenApi(refreshToken)
      .then((res) => {
        localStorage.setItem("token", res.data.accessToken);
        localStorage.setItem("refreshToken", res.data.refreshToken);
      })
      .catch((reason) => {
        if (401 === reason?.response?.status) {
          localStorage.clear();
          location.reload();
        }
      });
    return Promise.reject(error);
  }
  return Promise.reject(error);
}

api.interceptors.response.use(onFulfilledResponse, onRejectedResponse);
api.interceptors.request.use(onFulfilledRequest, onRejectedRequest);
