import { useTranslation } from "react-i18next";
import { Box, Button } from "@mui/material";
import { Form, Formik } from "formik";
import React, { useEffect } from "react";
import FormikDatePickerField from "../../shared/FormikDatePickerField";
import dayjs from "dayjs";

const PeriodCustomForm = ({ buttonText, initialValues, handleSubmit }) => {
  const { t } = useTranslation();

  const validatePeriod = (values) => {
    console.log(values);
    let errors = {};
    if (
      values.periodFrom &&
      values.periodTo &&
      dayjs(values.periodTo).isBefore(values.periodFrom)
    ) {
      errors.periodTo = t("END_DATE_SHOULD_NOT_BE_LESS_THAN_START_DATE");
    }
    return errors;
  };

  return (
    <Box pb={2} px={2} minWidth={400}>
      <Formik
        initialValues={{
          period: "CUSTOM",
          periodFrom: "",
          periodTo: "",
          ...initialValues,
        }}
        onSubmit={handleSubmit}
        validate={validatePeriod}
      >
        <Form>
          <FormikDatePickerField
            name={"periodFrom"}
            label={`${t("START_DATE")} *`}
            maxDateTime={dayjs()}
          />
          <FormikDatePickerField
            name={"periodTo"}
            label={`${t("END_DATE")} *`}
            maxDateTime={dayjs()}
          />
          <Box py={0.5} />
          <Button color="secondary" variant="contained" fullWidth type="submit">
            {buttonText}
          </Button>
        </Form>
      </Formik>
    </Box>
  );
};
export default PeriodCustomForm;
